import React from 'react';
import { connect } from 'react-redux';
import { Button, CircularProgress, FormControlLabel, FormGroup, Grid, Stack, Switch, TextField } from '@mui/material';
import TemplateService from '../services/TemplateService.js';
import PurchaseSearchResult from '../components/PurchaseSearchResult';
import PurchaseService from '../services/PurchaseService';
import SettlementService from '../services/SettlementService';
import PurchaseTagsService from '../services/PurchaseTagsService';
import CustomerRatingService from '../services/CustomerRatingService.js';
import { Box } from '@mui/system';
import OkpdSelection from './OkpdSelection';
import IndustrySelection from './IndustrySelection.js';
import { CustomerSelection } from './CustomerSelection.js';
import styles from '../css/PurchaseSearchPanel/PurchaseSearchPanel.module.css'
import BpCheckbox from "./CustomCheckbox";
import BpCheckboxCircle from "./CustomCheckboxRounded";
import { NotifyDays } from "../constants/notifyDays";
import PurchaseSearchForm from "./PurchaseSearchForm";
import { isMobile } from "react-device-detect";

class Templates extends React.Component {
    constructor(props) {
        super(props);

        this.handleDayOfWeekNotificationChange = this.handleDayOfWeekNotificationChange.bind(this);
        this.handlePageSearch = this.handlePageSearch.bind(this);
        this.searchResultsRef = React.createRef();
        this.state = {
            scroll: false
        }
    }

    componentDidMount() {
        this.props.onInitTemplatePage();
        this.props.onGetTemplates();

        if (isMobile) {
            window.addEventListener("scroll", () => {
                this.setState({
                    scroll: window.scrollY < 1900
                })
            });
        }
    }

    //rerun search when sortBy changed
    componentDidUpdate(prevProps) {
        if (prevProps.sortBy !== this.props.sortBy || prevProps.ascending !== this.props.ascending) {
            this.props.onSearchPurchases(this.props, 0, false);
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.props.onSearchPurchases(this.props, 0, false);
        }
    }

    handlePageSearch(pageNumber) {
        this.searchResultsRef.current.scrollIntoView(); //scroll to top of results
        this.props.onSearchPurchases(this.props, pageNumber, false); //(props, currentPageNumber, resetMarkedPurchases)
    }

    handleDayOfWeekNotificationChange(event) {
        let isChecked = event.target.checked;
        let value = parseInt(event.target.value);

        if (isChecked) {
            this.props.onAddDayNotify(value);
        } else {
            this.props.onRemoveDayNotify(value);
        }
    }

    render() {
        return (
            <React.Fragment>
                <OkpdSelection />
                <IndustrySelection />
                <CustomerSelection />
                <form onSubmit={(event) => {
                    event.preventDefault();
                }} style={{ padding: 0 }} className={styles.templatesWrapper}
                >
                    <Stack spacing={2} className={styles.templatesContainer} sx={{ m: 0, p: 3 }}>
                        {
                            this.props.isTemplatesLoading ?
                                <CircularProgress /> :
                                this.props.templates.length > 0 ?
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: "12px",
                                            p: 0,
                                            m: 0,
                                        }}
                                    >
                                        {
                                            this.props.templates.map(template => {
                                                let variant = template.id === this.props.templateId ? "contained" : "outlined";
                                                return <Box key={template.id} sx={{
                                                    p: 0,
                                                    m: 0
                                                }}>
                                                    <Button variant={variant} sx={{
                                                        fontFamily: 'SF Pro Text, serif',
                                                        fontSize: "14px",
                                                        textTransform: "unset!important",
                                                        letterSpacing: "-0.41px",
                                                        lineHeight: "unset!important",
                                                        fontWeight: "400",
                                                        padding: "5px 12px",
                                                        borderRadius: "8px",
                                                        width: "unset!important"
                                                    }}
                                                        onClick={() => this.props.onGetTemplate(template.id, this.props)}>{template.name}</Button>
                                                </Box>
                                            })
                                        }</Box>
                                    :
                                    "Шаблонов еще нет"
                        }
                    </Stack>
                    {
                        !!this.props.templateId &&
                        <Grid className={styles.innerContainer}
                            style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                            <Grid item xs={12}>
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                        Наименование шаблона
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            id="template_name"
                                            variant="outlined"
                                            fullWidth
                                            sx={{
                                                input: {
                                                    padding: 0
                                                }
                                            }}
                                            onChange={(event) => this.props.onTemplateNameChanges(event.target.value)}
                                            value={this.props.name}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={3} className={styles.gridRow + " " + styles.gridRowInline}>
                                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                        Уведомлять на почту о новых закупках
                                    </Grid>
                                    <Grid item xs={9}>
                                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <FormControlLabel
                                                label=''
                                                control={isMobile ? <Switch /> : <BpCheckbox />}
                                                sx={{ width: isMobile ? 'unset' : 180 }}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        this.props.onEmailNotifyEnable();
                                                    } else {
                                                        this.props.onEmailNotifyDisable();
                                                    }
                                                }}
                                                checked={this.props.notifyIsEnabled}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                        Уведомление
                                    </Grid>
                                    <Grid item xs={9}>
                                        <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: "10px" }}>
                                            {
                                                NotifyDays.map((day) => (
                                                    <FormControlLabel
                                                        className={styles.formControlItem}
                                                        key={day.name}
                                                        control={<BpCheckboxCircle />}
                                                        label={day.name}
                                                        onChange={this.handleDayOfWeekNotificationChange}
                                                        value={day.id}
                                                        checked={this.props.notifyDays.some(notifyDay => notifyDay === day.id)}
                                                    />
                                                ))
                                            }
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <PurchaseSearchForm
                                searchResultsRef={this.searchResultsRef}
                                searchPanelProps={this.props}
                                keywords={this.props.keywords}
                                onKeywordsChanges={this.props.onKeywordsChanges}
                                searchInFoundKeywords={this.props.searchInFoundKeywords}
                                onSearchInFoundKeywordsChanges={this.props.onSearchInFoundKeywordsChanges}
                                ignoreKeywords={this.props.ignoreKeywords}
                                onIgnoreKeywordsChanges={this.props.onIgnoreKeywordsChanges}
                                shouldSearchInDocuments={this.props.shouldSearchInDocuments}
                                onShouldSearchInDocumentsChanges={this.props.onShouldSearchInDocumentsChanges}
                                shouldSearchInOkpdNames={this.props.shouldSearchInOkpdNames}
                                onShouldSearchInOkpdNamesChanges={this.props.onShouldSearchInOkpdNamesChanges}
                                industryCodes={this.props.industryCodes}
                                onRemoveSingleIndustrySelection={this.props.onRemoveSingleIndustrySelection}
                                onOpenIndustrySelection={this.props.onOpenIndustrySelection}
                                okpdCodes={this.props.okpdCodes}
                                onRemoveSingleOkpdSelection={this.props.onRemoveSingleOkpdSelection}
                                onOpenOkpdSelection={this.props.onOpenOkpdSelection}
                                settlements={this.props.settlements}
                                settlementsLoading={this.props.settlementsLoading}
                                kladrCodes={this.props.kladrCodes}
                                onSearchSettlements={this.props.onSearchSettlements}
                                onClearSettlements={this.props.onClearSettlements}
                                onKladrCodesChanges={this.props.onKladrCodesChanges}
                                stages={this.props.stages}
                                onAddStage={this.props.onAddStage}
                                onRemoveStage={this.props.onRemoveStage}
                                auctionTypes={this.props.auctionTypes}
                                onAddAuctionType={this.props.onAddAuctionType}
                                onRemoveAuctionType={this.props.onRemoveAuctionType}
                                laws={this.props.laws}
                                onAddLawChanges={this.props.onAddLawChanges}
                                onRemoveLawChanges={this.props.onRemoveLawChanges}
                                havePrepayment={this.props.havePrepayment}
                                onHavePrepaymentChanges={this.props.onHavePrepaymentChanges}
                                haveRecommendation={this.props.haveRecommendation}
                                onHaveRecommendationChanges={this.props.onHaveRecommendationChanges}
                                priceFrom={this.props.priceFrom}
                                onPriceFromChanges={this.props.onPriceFromChanges}
                                priceTo={this.props.priceTo}
                                onPriceToChanges={this.props.onPriceToChanges}
                                shouldIncludeEmptyPrice={this.props.shouldIncludeEmptyPrice}
                                onExistMinimumPrice={this.props.onExistMinimumPrice}
                                datePublicationFrom={this.props.datePublicationFrom}
                                onDatePublicationFromChanges={this.props.onDatePublicationFromChanges}
                                datePublicationTo={this.props.datePublicationTo}
                                onDatePublicationToChanges={this.props.onDatePublicationToChanges}
                                dateExpirationFrom={this.props.dateExpirationFrom}
                                dateExpirationTo={this.props.dateExpirationTo}
                                onDateExpirationFromChanges={this.props.onDateExpirationFromChanges}
                                onDateExpirationToChanges={this.props.onDateExpirationToChanges}
                                customers={this.props.customers}
                                onOpenSearchOrganizations={this.props.onOpenSearchOrganizations}
                                onSearchPurchases={this.props.onSearchPurchases}
                            />

                            <Grid item xs={12}>
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={3} className={this.state.scroll ? styles.buttonContainer + " " + styles.scrollButton : styles.buttonContainer}>
                                        <Button
                                            className={styles.searchButton}
                                            onClick={() => {
                                                this.props.onSearchPurchases(this.props, 0, true)
                                                if (isMobile) {
                                                    this.searchResultsRef.current?.scrollIntoView({ behavior: 'smooth' })
                                                }
                                            }}
                                            variant="contained"
                                            type="submit"
                                        >
                                            Найти
                                        </Button>
                                    </Grid>
                                    <Grid item xs={9} className={styles.searchButtonContainerTemplates}>
                                        <div className={styles.searchButtonInnerContainerTemplates}>
                                            <Button
                                                sx={{
                                                    color: "#2e7d32",
                                                    fontWeight: "400",
                                                    '&:hover': {
                                                        color: '#2e7d32 !important'
                                                    }
                                                }}
                                                onClick={() => this.props.onSaveTemplate(this.props)}
                                                variant="outlined"
                                                disabled={!this.props.canSaveTemplate}
                                                color="success"
                                            >
                                                Сохранить изменения
                                            </Button>

                                            <Button
                                                sx={{
                                                    color: "#d32f2f",
                                                    fontWeight: "400"
                                                }}
                                                onClick={() => this.props.onRemoveTemplate(this.props)}
                                                variant="outlined"
                                                color="error"
                                            >
                                                Удалить шаблон
                                            </Button>

                                            <Button
                                                startIcon={<img src="/close_icon.svg" alt="bookmark" />}
                                                variant="text"
                                                onClick={() => {
                                                    this.props.onResetSearch(this.props.currentPage)
                                                    // this.props.onGetTemplate(this.props.templateId, this.props)
                                                }}>Сбросить
                                                шаблон</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </form>
                <Box ref={this.searchResultsRef} sx={{ mt: 2 }} className={styles.purchaseSearchResultContainer}>
                    <PurchaseSearchResult onPageSearch={this.handlePageSearch} />
                </Box>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        keywords: state.templatesState.keywords,
        searchInFoundKeywords: state.templatesState.searchInFoundKeywords,
        ignoreKeywords: state.templatesState.ignoreKeywords,
        shouldSearchInDocuments: state.templatesState.shouldSearchInDocuments,
        shouldSearchInOkpdNames: state.templatesState.shouldSearchInOkpdNames,
        havePrepayment: state.templatesState.havePrepayment,
        haveRecommendation: state.templatesState.haveRecommendation,
        laws: state.templatesState.laws,
        datePublicationFrom: state.templatesState.datePublicationFrom,
        datePublicationTo: state.templatesState.datePublicationTo,
        dateExpirationFrom: state.templatesState.dateExpirationFrom,
        dateExpirationTo: state.templatesState.dateExpirationTo,
        settlements: state.templatesState.settlements,
        priceFrom: state.templatesState.priceFrom,
        priceTo: state.templatesState.priceTo,
        stages: state.templatesState.stages,
        kladrCodes: state.templatesState.kladrCodes,
        auctionTypes: state.templatesState.auctionTypes,
        okpdCodes: state.templatesState.okpdCodes,
        industryCodes: state.templatesState.industryCodes,
        customers: state.templatesState.customers,
        shouldIncludeEmptyPrice: state.templatesState.shouldIncludeEmptyPrice,
        sortBy: state.templatesState.sortBy,
        ascending: state.templatesState.ascending,
        templateId: state.templatesState.templateId,
        templates: state.templatesState.templates,
        canSaveTemplate: state.templatesState.canSaveTemplate,
        purchases: state.templatesState.purchases,
        name: state.templatesState.name,
        isSuccessNotificationShow: state.templatesState.isSuccessNotificationShow,
        notificationMessage: state.templatesState.notificationMessage,
        notificationKey: state.templatesState.notificationKey,
        notifyDays: state.templatesState.notifyDays,
        notifyIsEnabled: state.templatesState.notifyIsEnabled,
        settlementsLoading: state.templatesState.settlementsLoading,
        selectedPurchase: state.templatesState.selectedPurchase,
        openTagDialog: state.templatesState.openTagDialog,
        isTemplatesLoading: state.templatesState.isTemplatesLoading,
        pageNumber: state.templatesState.pageNumber,
        currentPage: state.pagesState.currentPage,
        pageSize: state.templatesState.pageSize,
        isTemplateLoading: state.templatesState.isTemplateLoading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onResetSearch: (currentPage) => {
            const action = {
                type: 'TEMPLATES_RESET_SEARCH_PURCHASES',
                currentPage: currentPage
            };
            dispatch(action);
        },
        onInitTemplatePage: () => {
            const action = {
                type: 'TEMPLATE_PAGE'
            };
            dispatch(action);
        },
        onSearchPurchases: (state, pageNumber, resetMarkedPurchases) => {
            const action = {
                type: 'TEMPLATES_SEARCH_PURCHASES'
            };
            dispatch(action);

            PurchaseService.search({
                pageSize: state.pageSize,
                keywords: state.keywords,
                searchInFoundKeywords: state.searchInFoundKeywords,
                ignoreKeywords: state.ignoreKeywords,
                shouldSearchInDocuments: state.shouldSearchInDocuments,
                shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
                havePrepayment: state.havePrepayment,
                haveRecommendation: state.haveRecommendation,
                laws: state.laws,
                datePublicationFrom: state.datePublicationFrom,
                datePublicationTo: state.datePublicationTo,
                dateExpirationFrom: state.dateExpirationFrom,
                dateExpirationTo: state.dateExpirationTo,
                priceFrom: parseInt(state.priceFrom),
                priceTo: parseInt(state.priceTo),
                stages: state.stages,
                kladrCodes: state.kladrCodes.map(opt => opt.code),
                auctionTypes: state.auctionTypes,
                okpdCodes: state.okpdCodes.flatMap(opt => {
                    if (opt.children.length > 0) {
                        return opt.children;
                    }
                    return opt.key;
                }),
                industryCodes: state.industryCodes.map(opt => {
                    return opt.key;
                }),
                customerIds: state.customers.map(opt => {
                    return opt.id;
                }),
                shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
                pageNumber: pageNumber,
                pageSize: state.pageSize,
                sortBy: state.sortBy,
                asc: state.ascending
            })
                .then(result => {
                    var customerOrgIds = result.collections.map(c => c.customerOrganizationId);

                    CustomerRatingService.getCustomerRatings(customerOrgIds)
                        .then(result => {
                            if (result) {
                                const action = {
                                    type: 'TEMPLATES_CUSTOMER_RATINGS_FOUND',
                                    customerRatings: result
                                };
                                dispatch(action);
                            }
                        })
                        .catch(() => {
                            const notificationAction = {
                                type: 'SNACKBAR_NOTIFICATION_SENT',
                                notification: {
                                    message: 'Произошла ошибка при получении оценок заказчиков',
                                    variant: 'error'
                                }
                            };
                            dispatch(notificationAction);
                        });

                    var purchaseIds = result.collections.map(c => c.id);
                    PurchaseTagsService.getPurchaseTags(purchaseIds)
                        .then(result => {
                            const action = {
                                type: 'TEMPLATES_PURCHASE_TAGS_FOUND',
                                purchaseTags: result
                            };
                            dispatch(action);
                        })
                        .catch(() => {
                            const notificationAction = {
                                type: 'SNACKBAR_NOTIFICATION_SENT',
                                notification: {
                                    message: 'Произошла ошибка при получении меток избранного',
                                    variant: 'error'
                                }
                            };
                            dispatch(notificationAction);
                        });

                    const action = {
                        type: 'PURCHASES_FOUND',
                        purchases: result.collections,
                        pageNumber: result.pageNumber,
                        pageSize: result.pageSize,
                        total: result.total,
                        pageCount: result.pageCount,
                        keywords: state.keywords,
                        searchInFoundKeywords: state.searchInFoundKeywords,
                        ignoreKeywords: state.ignoreKeywords,
                        shouldSearchInDocuments: state.shouldSearchInDocuments,
                        shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
                        havePrepayment: state.havePrepayment,
                        haveRecommendation: state.haveRecommendation,
                        laws: state.laws,
                        datePublicationFrom: state.datePublicationFrom,
                        datePublicationTo: state.datePublicationTo,
                        dateExpirationFrom: state.dateExpirationFrom,
                        dateExpirationTo: state.dateExpirationTo,
                        priceFrom: state.priceFrom,
                        priceTo: state.priceTo,
                        stages: state.stages,
                        auctionTypes: state.auctionTypes,
                        kladrCodes: state.kladrCodes,
                        okpdCodes: state.okpdCodes,
                        industryCodes: state.industryCodes,
                        customers: state.customers,
                        shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
                        currentPage: state.currentPage,
                        resetMarkedPurchases: resetMarkedPurchases,
                        pageSize: state.pageSize
                    };
                    dispatch(action);
                })
                .catch(() => {
                    const action = {
                        type: 'TEMPLATES_SEARCH_PURCHASES_FAILED'
                    };
                    dispatch(action);

                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при поиске закупок по шаблону',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                })
        },
        onSearchSettlements: (keyword) => {
            const action = {
                type: 'TEMPLATES_SETTLEMENTS_SEARCH'
            };
            dispatch(action);
            SettlementService.search(keyword)
                .then(settlements => {
                    const action = {
                        type: 'TEMPLATES_SETTLEMENTS_FOUND',
                        settlements: settlements
                    };
                    dispatch(action);
                })
                .catch(() => {
                    const action = {
                        type: 'TEMPLATES_SETTLEMENTS_SEARCH_FAILED'
                    };
                    dispatch(action);

                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при поиске адреса',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                })
        },
        onClearSettlements: () => {
            const action = {
                type: 'TEMPLATES_SETTLEMENTS_RESET'
            };
            dispatch(action);
        },
        onGetTemplate: (templateId, state) => {

            dispatch({ type: 'TEMPLATE_LOADING_STARTED' })

            TemplateService.getById(templateId)
                .then(template => {
                    const action = {
                        type: 'GET_TEMPLATE',
                        keywords: template.keywords,
                        searchInFoundKeywords: template.searchInFoundKeywords,
                        ignoreKeywords: template.ignoreKeywords,
                        shouldSearchInDocuments: template.shouldSearchInDocuments,
                        shouldSearchInOkpdNames: template.shouldSearchInOkpdNames,
                        havePrepayment: template.havePrepayment,
                        haveRecommendation: template.haveRecommendation,
                        laws: template.laws,
                        datePublicationFrom: template.datePublicationFrom,
                        datePublicationTo: template.datePublicationTo,
                        dateExpirationFrom: template.dateExpirationFrom,
                        dateExpirationTo: template.dateExpirationTo,
                        priceFrom: template.priceFrom,
                        priceTo: template.priceTo,
                        stages: template.stages,
                        kladrCodes: template.kladrCodes,
                        auctionTypes: template.auctionTypes,
                        okpdCodes: template.okpdCodes,
                        industryCodes: template.industryCodes,
                        customers: template.customers,
                        shouldIncludeEmptyPrice: template.shouldIncludeEmptyPrice,

                        name: template.name,
                        templateId: templateId,

                        purchases: [],
                        notifyIsEnabled: template.emailNotificationOptions ? template.emailNotificationOptions.isEnabled : false,
                        notifyDays: template.emailNotificationOptions ? template.emailNotificationOptions.notifyDays : [],
                        currentPage: state.currentPage
                    };

                    dispatch(action);

                    PurchaseService.search({
                        pageSize: state.pageSize,
                        keywords: template.keywords,
                        searchInFoundKeywords: template.searchInFoundKeywords,
                        ignoreKeywords: template.ignoreKeywords,
                        shouldSearchInDocuments: template.shouldSearchInDocuments,
                        shouldSearchInOkpdNames: template.shouldSearchInOkpdNames,
                        havePrepayment: template.havePrepayment,
                        haveRecommendation: template.haveRecommendation,
                        laws: template.laws,
                        datePublicationFrom: template.datePublicationFrom,
                        datePublicationTo: template.datePublicationTo,
                        dateExpirationFrom: template.dateExpirationFrom,
                        dateExpirationTo: template.dateExpirationTo,
                        priceFrom: parseInt(template.priceFrom),
                        priceTo: parseInt(template.priceTo),
                        stages: template.stages,
                        kladrCodes: template.kladrCodes.map(opt => opt.code),
                        auctionTypes: template.auctionTypes,
                        okpdCodes: template.okpdCodes.flatMap(item => {
                            if (item.children.length > 0) {
                                return item.children;
                            }
                            return item.key;
                        }),
                        industryCodes: template.industryCodes.map(opt => {
                            return opt.key;
                        }),
                        customerIds: template.customers.map(opt => {
                            return opt.id;
                        }),
                        shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
                        sortBy: state.sortBy,
                        asc: state.ascending
                    })
                        .then(result => {
                            var customerOrgIds = result.collections.map(c => c.customerOrganizationId);

                            CustomerRatingService.getCustomerRatings(customerOrgIds)
                                .then(result => {
                                    if (result) {
                                        const action = {
                                            type: 'TEMPLATES_CUSTOMER_RATINGS_FOUND',
                                            customerRatings: result
                                        };
                                        dispatch(action);
                                    }
                                })
                                .catch(() => {
                                    const notificationAction = {
                                        type: 'SNACKBAR_NOTIFICATION_SENT',
                                        notification: {
                                            message: 'Произошла ошибка при получении оценок заказчиков',
                                            variant: 'error'
                                        }
                                    };
                                    dispatch(notificationAction);
                                });

                            var purchaseIds = result.collections.map(c => c.id);
                            PurchaseTagsService.getPurchaseTags(purchaseIds)
                                .then(result => {
                                    const action = {
                                        type: 'TEMPLATES_PURCHASE_TAGS_FOUND',
                                        purchaseTags: result
                                    };
                                    dispatch(action);
                                })
                                .catch(() => {
                                    const notificationAction = {
                                        type: 'SNACKBAR_NOTIFICATION_SENT',
                                        notification: {
                                            message: 'Произошла ошибка при получении меток избранного',
                                            variant: 'error'
                                        }
                                    };
                                    dispatch(notificationAction);
                                });

                            const action = {
                                type: 'PURCHASES_FOUND',
                                purchases: result.collections,
                                pageNumber: result.pageNumber,
                                pageSize: result.pageSize,
                                total: result.total,
                                pageCount: result.pageCount,
                                keywords: template.keywords,
                                searchInFoundKeywords: template.searchInFoundKeywords,
                                ignoreKeywords: template.ignoreKeywords,
                                shouldSearchInDocuments: template.shouldSearchInDocuments,
                                shouldSearchInOkpdNames: template.shouldSearchInOkpdNames,
                                havePrepayment: template.havePrepayment,
                                haveRecommendation: template.haveRecommendation,
                                laws: template.laws,
                                datePublicationFrom: template.datePublicationFrom,
                                datePublicationTo: template.datePublicationTo,
                                dateExpirationFrom: template.dateExpirationFrom,
                                dateExpirationTo: template.dateExpirationTo,
                                priceFrom: template.priceFrom,
                                priceTo: template.priceTo,
                                stages: template.stages,
                                kladrCodes: template.kladrCodes,
                                auctionTypes: template.auctionTypes,
                                okpdCodes: template.okpdCodes,
                                industryCodes: template.industryCodes,
                                customers: template.customers,
                                shouldIncludeEmptyPrice: template.shouldIncludeEmptyPrice,
                                currentPage: state.currentPage
                            };
                            dispatch(action);
                        })
                        .catch(() => {
                            const notificationAction = {
                                type: 'SNACKBAR_NOTIFICATION_SENT',
                                notification: {
                                    message: 'Произошла ошибка при получении закупок по шаблону',
                                    variant: 'error'
                                }
                            };
                            dispatch(notificationAction);
                        })
                })
                .catch(() => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при получении шаблона',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                })
        },
        onGetTemplates: () => {
            const action = {
                type: 'GET_TEMPLATES_STARTED'
            };
            dispatch(action);
            TemplateService.getAll()
                .then(templates => {
                    const action = {
                        type: 'GET_TEMPLATES_FINISHED',
                        templates: templates
                    };

                    dispatch(action);
                })
                .catch(() => {
                    const action = {
                        type: 'GET_TEMPLATES_ERROR'
                    };
                    dispatch(action);

                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Не удалось получить доступные шаблоны',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                });
        },
        onKeywordsChanges: (keywords) => {
            const action = {
                type: 'TEMPLATES_KEYWORDS_CHANGES',
                keywords: keywords
            }
            dispatch(action);
        },
        onSearchInFoundKeywordsChanges: (searchInFoundKeywords) => {
            const action = {
                type: 'TEMPLATES_SEARCH_IN_FOUND_KEYWORDS_CHANGES',
                searchInFoundKeywords: searchInFoundKeywords
            }
            dispatch(action);
        },
        onIgnoreKeywordsChanges: (ignoreKeywords) => {
            const action = {
                type: 'TEMPLATES_IGNORE_KEYWORDS_CHANGES',
                ignoreKeywords: ignoreKeywords
            }
            dispatch(action);
        },
        onShouldSearchInDocumentsChanges: (value) => {
            const action = {
                type: 'TEMPLATES_SHOULD_SEARCH_IN_DOCUMENTS_CHANGES',
                shouldSearchInDocuments: value
            }
            dispatch(action);
        },
        onShouldSearchInOkpdNamesChanges: (value) => {
            const action = {
                type: 'TEMPLATES_SHOULD_SEARCH_IN_OKPD_NAMES_CHANGES',
                shouldSearchInOkpdNames: value
            };
            dispatch(action);
        },
        onKladrCodesChanges: (kladrCodes) => {
            const action = {
                type: 'TEMPLATES_KLADR_CODES_CHANGES',
                kladrCodes: kladrCodes
            }
            dispatch(action);
        },
        onExistMinimumPrice: (value) => {
            const action = {
                type: 'TEMPLATES_SHOULD_INCLUDE_EMPTY_PRICE_CHANGES',
                shouldIncludeEmptyPrice: value
            };
            dispatch(action);
        },
        onAddStage: (stageId) => {
            const action = {
                type: 'TEMPLATES_ADD_STAGE',
                stageId: stageId
            }
            dispatch(action);
        },
        onRemoveStage: (stageId) => {
            const action = {
                type: 'TEMPLATES_REMOVE_STAGE',
                stageId: stageId
            }
            dispatch(action);
        },
        onAddAuctionType: (auctionTypeId) => {
            const action = {
                type: 'TEMPLATES_ADD_AUCTION_TYPE',
                auctionTypeId: auctionTypeId
            }
            dispatch(action);
        },
        onRemoveAuctionType: (auctionTypeId) => {
            const action = {
                type: 'TEMPLATES_REMOVE_AUCTION_TYPE',
                auctionTypeId: auctionTypeId
            }
            dispatch(action);
        },
        onPriceFromChanges: (value) => {
            const action = {
                type: 'TEMPLATES_PRICE_FROM_CHANGES',
                priceFrom: value
            }
            dispatch(action);
        },
        onPriceToChanges: (value) => {
            const action = {
                type: 'TEMPLATES_PRICE_TO_CHANGES',
                priceTo: value
            }
            dispatch(action);
        },
        onHavePrepaymentChanges: (value) => {
            const action = {
                type: 'TEMPLATES_HAVE_PREPAYMENT_CHANGES',
                havePrepayment: value
            }
            dispatch(action);
        },
        onHaveRecommendationChanges: (value) => {
            const action = {
                type: 'TEMPLATES_HAVE_RECOMMENDATION_CHANGES',
                haveRecommendation: value
            }
            dispatch(action);
        },
        onDatePublicationFromChanges: (value) => {
            const action = {
                type: 'TEMPLATES_DATE_PUBLICATION_FROM_CHANGES',
                datePublicationFrom: value
            }
            dispatch(action);
        },
        onDatePublicationToChanges: (value) => {
            const action = {
                type: 'TEMPLATES_DATE_PUBLICATION_TO_CHANGES',
                datePublicationTo: value
            }
            dispatch(action);
        },
        onDateExpirationFromChanges: (value) => {
            const action = {
                type: 'TEMPLATES_DATE_EXPIRATION_FROM_CHANGES',
                dateExpirationFrom: value
            };
            dispatch(action);
        },

        onDateExpirationToChanges: (value) => {
            const action = {
                type: 'TEMPLATES_DATE_EXPIRATION_TO_CHANGES',
                dateExpirationTo: value
            };
            dispatch(action);
        },

        onAddLawChanges: (value) => {
            const action = {
                type: 'TEMPLATES_ADD_LAW_CHANGES',
                lawId: value
            }
            dispatch(action);
        },
        onRemoveLawChanges: (value) => {
            const action = {
                type: 'TEMPLATES_REMOVE_LAW_CHANGES',
                lawId: value
            }
            dispatch(action);
        },
        onShouldIncludeEmptyPriceChanges: (value) => {
            const action = {
                type: 'TEMPLATES_SHOULD_INCLUDE_EMPTY_PRICE_CHANGES',
                shouldIncludeEmptyPrice: value
            }
            dispatch(action);
        },
        onRemoveTemplate: (state) => {
            TemplateService.remove(state.templateId)
                .then(() => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Шаблон успешно удален',
                            variant: 'success'
                        }
                    };
                    dispatch(notificationAction);

                    const action = {
                        type: 'REMOVE_TEMPLATE',
                        templateId: state.templateId,
                        currentPage: state.currentPage
                    };
                    dispatch(action);
                })
                .catch(() => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при удалении шаблона',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                });
        },
        onSaveTemplate: (state) => {
            TemplateService.update(state.templateId, {
                name: state.name,
                keywords: state.keywords,
                searchInFoundKeywords: state.searchInFoundKeywords,
                ignoreKeywords: state.ignoreKeywords,
                shouldSearchInDocuments: state.shouldSearchInDocuments,
                shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
                havePrepayment: state.havePrepayment,
                haveRecommendation: state.haveRecommendation,
                laws: state.laws,
                datePublicationFrom: state.datePublicationFrom,
                datePublicationTo: state.datePublicationTo,
                dateExpirationFrom: state.dateExpirationFrom,
                dateExpirationTo: state.dateExpirationTo,
                priceFrom: state.priceFrom,
                priceTo: state.priceTo,
                stages: state.stages,
                kladrCodes: state.kladrCodes,
                auctionTypes: state.auctionTypes,
                okpdCodes: state.okpdCodes.map((code) => {
                    return { key: code.key, children: code.children.flatMap((child) => child.key ?? child) }
                }),
                industryCodes: state.industryCodes,
                customers: state.customers,
                shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,

                emailNotificationOptions: {
                    notifyDays: state.notifyDays,
                    isEnabled: state.notifyIsEnabled
                }
            })
                .then((template) => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Шаблон успешно сохранен',
                            variant: 'success'
                        }
                    };
                    dispatch(notificationAction);

                    const action = {
                        type: 'TEMPLATE_SAVED',
                        template: template
                    }
                    dispatch(action);
                })
                .catch(() => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при редактировании шаблона',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                });
        },
        onTemplateNameChanges: (templateName) => {
            const action = {
                type: 'TEMPLATE_NAME_CHANGES',
                templateName: templateName
            }
            dispatch(action);
        },
        onAddDayNotify: (value) => {
            const action = {
                type: 'ADD_DAY_NOTIFY',
                notifyDay: parseInt(value)
            }
            dispatch(action);
        },
        onRemoveDayNotify: (value) => {
            const action = {
                type: 'REMOVE_DAY_NOTIFY',
                notifyDay: parseInt(value)
            }
            dispatch(action);
        },
        onEmailNotifyEnable: () => {
            const action = {
                type: 'EMAIL_NOTIFY_ENABLE'
            }
            dispatch(action);
        },
        onEmailNotifyDisable: () => {
            const action = {
                type: 'EMAIL_NOTIFY_DISABLE'
            }
            dispatch(action);
        },
        onCloseTagDialog: (purchaseId) => {
            const purchaseIds = [purchaseId];
            PurchaseTagsService.getPurchaseTags(purchaseIds)
                .then(result => {
                    const action = {
                        type: 'REFRESH_PURCHASE_TAGS',
                        purchase: result
                    };
                    dispatch(action);
                })
                .catch(() => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при получении избранных закупок',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                });
        },
        onOpenTagDialog: (selectedPurchase) => {
            const action = {
                type: 'OPEN_TAG_DIALOG',
                selectedPurchase: selectedPurchase
            };
            dispatch(action);
        },
        onOpenOkpdSelection: (checkedOkpdCodes) => {
            const action = {
                type: 'OKPD_SELECTION_OPENED',
                checkedOkpdCodes: checkedOkpdCodes
            };
            dispatch(action);
        },
        onRemoveSingleOkpdSelection: (key) => {
            const action = {
                type: 'TEMPLATES_SINGLE_SELECTED_OKPD_REMOVED',
                key: key
            };
            dispatch(action);
        },
        onOpenIndustrySelection: (checkedIndustryCodes) => {
            const action = {
                type: 'INDUSTRIES_SELECTION_OPENED',
                checkedIndustryCodes: checkedIndustryCodes
            };
            dispatch(action);
        },
        onRemoveSingleIndustrySelection: (key) => {
            const action = {
                type: 'TEMPLATES_SINGLE_SELECTED_INDUSTRY_REMOVED',
                key: key
            };
            dispatch(action);
        },
        onOpenSearchOrganizations: (checkedOrganizations) => {
            const action = {
                type: 'CUSTOMER_SELECTION_OPENED',
                checkedOrganizations: checkedOrganizations
            };
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
