import React from "react"
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Tooltip, Popup, useMapEvents } from 'react-leaflet';
import { useSelector, useDispatch } from "react-redux";
import PurchaseService from "../../services/PurchaseService";
import Geohash from "latlon-geohash";

import "./assets/leaflet.css";
import markerIcon from "./assets/images/marker-icon.png";

const newMarker = new L.icon({
    iconUrl: markerIcon,
    iconSize: [25, 41],
    iconAnchor: [17, 41]
})

export const ClickableMarker = (props) => {
    const purchaseCluster = props.purchaseCluster;

    const dispatch = useDispatch();
    const reduxState = useSelector((state) => {
        return {
            keywords: state.purchasesState.keywords,
            ignoreKeywords: state.purchasesState.ignoreKeywords,
            shouldSearchInDocuments: state.purchasesState.shouldSearchInDocuments,
            shouldSearchInOkpdNames: state.purchasesState.shouldSearchInOkpdNames,
            havePrepayment: state.purchasesState.havePrepayment,
            haveRecommendation: state.purchasesState.haveRecommendation,
            laws: state.purchasesState.laws,
            datePublicationFrom: state.purchasesState.datePublicationFrom,
            datePublicationTo: state.purchasesState.datePublicationTo,
            dateExpirationFrom: state.purchasesState.dateExpirationFrom,
            dateExpirationTo: state.purchasesState.dateExpirationTo,
            settlements: state.purchasesState.settlements,
            priceFrom: state.purchasesState.priceFrom,
            priceTo: state.purchasesState.priceTo,
            stages: state.purchasesState.stages,
            kladrCodes: state.purchasesState.kladrCodes,
            auctionTypes: state.purchasesState.auctionTypes,
            suggestions: state.purchasesState.suggestions,
            settlementsLoading: state.purchasesState.settlementsLoading,
            okpdCodes: state.purchasesState.okpdCodes,
            industryCodes: state.purchasesState.industryCodes,
            customers: state.purchasesState.customers,
            shouldIncludeEmptyPrice: state.purchasesState.shouldIgnoreEmptyPrice
        };
    })

    const startSearch = () => {
        const geohashBounds = Geohash.bounds(purchaseCluster.geoHash);

        PurchaseService.search({
            keywords: reduxState.keywords,
            ignoreKeywords: reduxState.ignoreKeywords,
            shouldSearchInDocuments: reduxState.shouldSearchInDocuments,
            shouldSearchInOkpdNames: reduxState.shouldSearchInOkpdNames,
            havePrepayment: reduxState.havePrepayment,
            haveRecommendation: reduxState.haveRecommendation,
            laws: reduxState.laws,
            datePublicationFrom: reduxState.datePublicationFrom,
            datePublicationTo: reduxState.datePublicationTo,
            dateExpirationFrom: reduxState.dateExpirationFrom,
            dateExpirationTo: reduxState.dateExpirationTo,
            priceFrom: parseInt(reduxState.priceFrom),
            priceTo: parseInt(reduxState.priceTo),
            stages: reduxState.stages,
            kladrCodes: reduxState.kladrCodes.map(opt => opt.code),
            industryCodes: reduxState.industryCodes.map(opt => opt.key),
            auctionTypes: reduxState.auctionTypes,
            okpdCodes: reduxState.okpdCodes.flatMap(item => {
                if (item.children.length > 0) {
                    return item.children;
                }
                return item.key;
            }),
            customerIds: reduxState.customers.map(opt => opt.id),
            shouldIncludeEmptyPrice: reduxState.shouldIncludeEmptyPrice,
            //в elastic границы northWest и southEast, а в leaflet - northEast и southWest, так что получаем одно из другого
            northWestBound: {
                lat: geohashBounds.ne.lat.toString(),
                lon: geohashBounds.sw.lon.toString()
            },
            southEastBound: {
                lat: geohashBounds.sw.lat.toString(),
                lon: geohashBounds.ne.lon.toString()
            },
        })
            .then((result) => {
                const action = {
                    type: 'MAP_SET_PURCHASES',
                    purchases: result.collections
                };
                dispatch(action);
            })
    }

    return (
        <React.Fragment>
            <Marker position={[purchaseCluster.location.latitude, purchaseCluster.location.longitude]} icon={newMarker}
                eventHandlers={{
                    click: (e) => {
                        startSearch();
                    }
                }}
            >
                {purchaseCluster.count > 1
                    ?
                    <Tooltip direction='right' offset={[-8, -2]} opacity={1} title="количество закупок" permanent>
                        <span>{purchaseCluster.count}</span>
                    </Tooltip>
                    :
                    null
                }
            </Marker>
        </React.Fragment>
    )
}
