import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link as routerLink } from 'react-router-dom';
import SettlementService from '../services/SettlementService';
import PurchaseService from '../services/PurchaseService';
import TemplateService from '../services/TemplateService';
import CustomerRatingService from '../services/CustomerRatingService';
import PurchaseTagsService from '../services/PurchaseTagsService';
import OkpdSelection from './OkpdSelection';
import IndustrySelection from './IndustrySelection';
import { CustomerSelection } from './CustomerSelection';
import PurchaseSearchResult from './PurchaseSearchResult';
import FormHelperText from '@mui/material/FormHelperText'
import styles from '../css/PurchaseSearchPanel/PurchaseSearchPanel.module.css'

//MUI
import Button from '@mui/material/Button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    SwipeableDrawer,
    TextField
} from '@mui/material';
import { Box } from '@mui/system';
import PurchaseSearchForm from "./PurchaseSearchForm";
import { isMobile } from "react-device-detect";
import { styled, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const TemplateCreationModal = (props) => {
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [name, setName] = React.useState(null);
    const dispatch = useDispatch();

    const onSaveTemplate = async (name, state) => {
        return TemplateService.add({
            name: name,
            keywords: state.keywords,
            searchInFoundKeywords: state.searchInFoundKeywords,
            ignoreKeywords: state.ignoreKeywords,
            shouldSearchInDocuments: state.shouldSearchInDocuments,
            shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
            havePrepayment: state.havePrepayment,
            haveRecommendation: state.haveRecommendation,
            laws: state.laws,
            datePublicationFrom: state.datePublicationFrom,
            datePublicationTo: state.datePublicationTo,
            dateExpirationFrom: state.dateExpirationFrom,
            dateExpirationTo: state.dateExpirationTo,
            priceFrom: parseInt(state.priceFrom),
            priceTo: parseInt(state.priceTo),
            stages: state.stages,
            kladrCodes: state.kladrCodes,
            auctionTypes: state.auctionTypes,
            okpdCodes: state.okpdCodes.map((code) => {
                return { key: code.key, children: code.children.flatMap((child) => child.key ?? child) }
            }),
            industryCodes: state.industryCodes,
            customers: state.customers,
            shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice
        })
            .then(() => {
                const notificationAction = {
                    type: 'SNACKBAR_NOTIFICATION_SENT',
                    notification: {
                        message: 'Шаблон успешно сохранен',
                        variant: 'success'
                    }
                };
                dispatch(notificationAction);
                props.onClose();
                setName(null);
                setErrorMessages([]);
            })
            .catch((error) => {
                console.log(error)
                if (error.errors['Name'].length > 0) {
                    setErrorMessages(error.errors['Name']);
                } else {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при сохранении шаблона',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                }
            });
    }

    const Puller = styled('div')(({ theme }) => ({
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: 'absolute',
        top: 8,
        left: 'calc(50% - 15px)'
    }));

    return (
        <React.Fragment>
            {
                isMobile ?
                    <SwipeableDrawer
                        anchor='bottom'
                        open={props.onOpen}
                        onClose={props.onClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        PaperProps={{
                            sx: {
                                width: "100%",
                                borderRadius: "16px 16px 0 0",
                                maxHeight: "80%",
                                height: "50%"
                            }
                        }}
                        scroll={"paper"}
                    >
                        <Puller />
                        <DialogTitle
                            sx={{
                                m: 0,
                                padding: '32px 16px 12px',
                                fontSize: "20px!important",
                                fontWeight: "600",
                                fontFamily: "SF Pro Text, sans-serif",
                                letterSpacing: "-0.41px"
                            }}
                            id="customized-dialog-title">
                            Сохранить как шаблон
                        </DialogTitle>

                        <DialogContent sx={{ borderBottom: "0.5px solid #E8E8E8", paddingTop: "12px", paddingLeft: "16px", paddingRight: "16px" }}>
                            <Box>
                                <ThemeProvider theme={{
                                    components: {
                                        MuiOutlinedInput: {
                                            styleOverrides: {
                                                root: {
                                                    padding: "9px 12px",
                                                    borderRadius: "4px",
                                                    fontSize: "16px",
                                                    fontFamily: "SF Pro Text, serif",
                                                    letterSpacing: "-0.41px",
                                                    '& label.Mui-focused': {
                                                        color: 'var(--TextField-brandBorderFocusedColor)',
                                                    },
                                                },
                                                input: {
                                                    padding: 0
                                                }
                                            }
                                        }
                                    }
                                }}>
                                    <TextField
                                        id="templateName"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Введите название шаблона"
                                        onChange={(event) => {
                                            var name = event.target.value;
                                            if (name) {
                                                setName(name);
                                            } else {
                                                setName(null);
                                            }
                                        }}
                                    />
                                </ThemeProvider>

                                {errorMessages.map((error, key) => (
                                    <FormHelperText error key={key}>
                                        *{error}
                                    </FormHelperText>
                                )
                                )}
                            </Box>
                        </DialogContent>

                        <DialogActions sx={{ justifyContent: 'flex-start', pt: 2, pb: 3, paddingLeft: '16px' }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    onSaveTemplate(name, props.state);
                                }}
                            >Сохранить</Button>
                            <Button
                                startIcon={<img src="/close_icon.svg" alt="Close" />} variant="text"
                                onClick={props.onClose}>Отменить</Button>
                        </DialogActions>
                    </SwipeableDrawer> :
                    <Dialog
                        open={props.onOpen}
                        onClose={props.onClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        PaperProps={{
                            sx: {
                                maxWidth: "600px",
                                width: "600px",
                                borderRadius: "16px",
                                maxHeight: "80%"
                            }
                        }}
                        scroll={"paper"}
                    >
                        <DialogTitle sx={{
                            m: 0,
                            p: 3,
                            fontSize: "22px!important",
                            fontWeight: "600",
                            fontFamily: "SF Pro Text, serif",
                            letterSpacing: "-0.41px"
                        }} id="customized-dialog-title">
                            Сохранить как шаблон
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={props.onClose}
                            sx={(theme) => ({
                                position: 'absolute',
                                right: 16,
                                top: 22,
                                color: theme.palette.grey[500],
                            })}
                        >
                            <img src="/close_icon.svg" alt="Close" />
                        </IconButton>
                        <DialogContent sx={{ borderBottom: "0.5px solid #E8E8E8", paddingTop: "12px" }}>
                            <Box>
                                <TextField
                                    id="templateName"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Введите название шаблона"
                                    style={{ padding: "0" }}
                                    onChange={(event) => {
                                        var name = event.target.value;
                                        if (name) {
                                            setName(name);
                                        } else {
                                            setName(null);
                                        }
                                    }}
                                />

                                {errorMessages.map((error, key) => (
                                    <FormHelperText error key={key}>
                                        *{error}
                                    </FormHelperText>
                                )
                                )}
                            </Box>
                        </DialogContent>

                        <DialogActions sx={{ justifyContent: 'flex-start', pt: 2, pb: 2, paddingLeft: '24px' }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    onSaveTemplate(name, props.state);
                                }}
                            >Сохранить</Button>
                            <Button
                                startIcon={<img src="/close_icon.svg" alt="Close" />} variant="text"
                                onClick={props.onClose}>Отменить</Button>
                        </DialogActions>
                    </Dialog>
            }
        </React.Fragment>
    )
}

class PurchaseSearchPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openConfirmSaveTemplate: false,
            scroll: false
        };

        this.handleCloseSaveTemplate = this.handleCloseSaveTemplate.bind(this);
        this.handlePageSearch = this.handlePageSearch.bind(this);

        this.searchResultsRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.redirectedFromMap === true) {
            this.props.onSearchPurchases(this.props, 0, false);
        }
        if (isMobile) {
            window.addEventListener("scroll", () => {
                this.setState({
                    scroll: window.scrollY < 1400
                })
            });
        }
    }

    componentDidUpdate(prevProps) {
        //rerun search when sortBy changed
        if (prevProps.sortBy !== this.props.sortBy || prevProps.ascending !== this.props.ascending) {
            this.props.onSearchPurchases(this.props, 0, false);
        }

        //rerun search when pageSize changed
        if (prevProps.pageSize !== this.props.pageSize) {
            this.props.onSearchPurchases(this.props, 0, false);
        }
    }

    handlePageSearch(pageNumber) {
        this.searchResultsRef.current.scrollIntoView(); //scroll to top of results
        this.props.onSearchPurchases(this.props, pageNumber, false); //(props, currentPageNumber, isNewSearch)
    }

    handleCloseSaveTemplate() {
        this.setState({ openConfirmSaveTemplate: false });
    }


    render() {
        return (
            <React.Fragment>
                <TemplateCreationModal
                    onOpen={this.state.openConfirmSaveTemplate}
                    onClose={this.handleCloseSaveTemplate}
                    state={this.props}
                />
                <OkpdSelection />
                <IndustrySelection />
                <CustomerSelection />

                <form
                    ref={(formRef) => { this.formRef = formRef }}
                    className={styles.container}
                    onSubmit={(event) => event.preventDefault()}
                >
                    <Grid style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                        <PurchaseSearchForm
                            searchResultsRef={this.searchResultsRef}
                            searchPanelProps={this.props}
                            keywords={this.props.keywords}
                            searchInFoundKeywords={this.props.searchInFoundKeywords}
                            ignoreKeywords={this.props.ignoreKeywords}
                            shouldSearchInDocuments={this.props.shouldSearchInDocuments}
                            shouldSearchInOkpdNames={this.props.shouldSearchInOkpdNames}
                            industryCodes={this.props.industryCodes}
                            okpdCodes={this.props.okpdCodes}
                            settlements={this.props.settlements}
                            settlementsLoading={this.props.settlementsLoading}
                            kladrCodes={this.props.kladrCodes}
                            stages={this.props.stages}
                            laws={this.props.laws}
                            havePrepayment={this.props.havePrepayment}
                            haveRecommendation={this.props.haveRecommendation}
                            priceFrom={this.props.priceFrom}
                            priceTo={this.props.priceTo}
                            shouldIncludeEmptyPrice={this.props.shouldIncludeEmptyPrice}
                            datePublicationFrom={this.props.datePublicationFrom}
                            datePublicationTo={this.props.datePublicationTo}
                            dateExpirationFrom={this.props.dateExpirationFrom}
                            dateExpirationTo={this.props.dateExpirationTo}
                            customers={this.props.customers}

                            onKeywordsChanges={this.props.onKeywordsChanges}
                            onSearchInFoundKeywordsChanges={this.props.onSearchInFoundKeywordsChanges}
                            onIgnoreKeywordsChanges={this.props.onIgnoreKeywordsChanges}
                            onShouldSearchInDocumentsChanges={this.props.onShouldSearchInDocumentsChanges}
                            onShouldSearchInOkpdNamesChanges={this.props.onShouldSearchInOkpdNamesChanges}
                            onRemoveSingleIndustrySelection={this.props.onRemoveSingleIndustrySelection}
                            onOpenIndustrySelection={this.props.onOpenIndustrySelection}
                            onRemoveSingleOkpdSelection={this.props.onRemoveSingleOkpdSelection}
                            onOpenOkpdSelection={this.props.onOpenOkpdSelection}
                            onSearchSettlements={this.props.onSearchSettlements}
                            onClearSettlements={this.props.onClearSettlements}
                            onKladrCodesChanges={this.props.onKladrCodesChanges}
                            onAddStage={this.props.onAddStage}
                            onRemoveStage={this.props.onRemoveStage}
                            auctionTypes={this.props.auctionTypes}
                            onAddAuctionType={this.props.onAddAuctionType}
                            onRemoveAuctionType={this.props.onRemoveAuctionType}
                            onAddLawChanges={this.props.onAddLawChanges}
                            onRemoveLawChanges={this.props.onRemoveLawChanges}
                            onHavePrepaymentChanges={this.props.onHavePrepaymentChanges}
                            onHaveRecommendationChanges={this.props.onHaveRecommendationChanges}
                            onPriceFromChanges={this.props.onPriceFromChanges}
                            onPriceToChanges={this.props.onPriceToChanges}
                            onExistMinimumPrice={this.props.onExistMinimumPrice}
                            onDatePublicationFromChanges={this.props.onDatePublicationFromChanges}
                            onDatePublicationToChanges={this.props.onDatePublicationToChanges}
                            onDateExpirationFromChanges={this.props.onDateExpirationFromChanges}
                            onDateExpirationToChanges={this.props.onDateExpirationToChanges}
                            onOpenSearchOrganizations={this.props.onOpenSearchOrganizations}
                            onSearchPurchases={this.props.onSearchPurchases}
                        />

                        <Grid item xs={12}>
                            <Grid container spacing={3} className={styles.gridRow}>
                                <Grid item xs={3} className={this.state.scroll ? styles.buttonContainer + " " + styles.scrollButton : styles.buttonContainer}>
                                    <Button
                                        className={styles.searchButton}
                                        variant="contained"
                                        onClick={() => {
                                            this.props.onSearchPurchases(this.props, 0, true);//(props, currentPageNumber, isNewSearch)
                                            if (isMobile) {
                                                this.searchResultsRef.current?.scrollIntoView({ behavior: 'smooth' })
                                            }
                                        }}
                                        type="submit"
                                    >Найти</Button>
                                </Grid>

                                <Grid item xs={9} className={styles.searchButtonContainer}>
                                    <div className={styles.searchButtonInnerContainer}>
                                        <Button
                                            startIcon={<img src="/bookmark_icon.svg" alt="bookmark" />}
                                            variant="text"
                                            onClick={() => this.setState({ openConfirmSaveTemplate: true })}>Сохранить
                                            как
                                            шаблон</Button>

                                        <Button
                                            component={routerLink}
                                            to="/map-search-page"
                                            variant="text"
                                            startIcon={<img src="/location_icon.svg" alt="location" />}
                                            onClick={() => this.props.onSearchPurchases(this.props, 0, false)} //(props, currentPageNumber, isNewSearch)
                                        >

                                            Показать на карте
                                        </Button>

                                    </div>

                                    <Button
                                        startIcon={<img src="/close_icon.svg" alt="bookmark" />} variant="text"
                                        onClick={() => this.props.onResetSearch(this.props.currentPage)}>Сбросить
                                        поиск</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </form>

                <Box sx={{ mt: 2 }} ref={this.searchResultsRef} className={styles.purchaseSearchResultContainer}>
                    <PurchaseSearchResult onPageSearch={this.handlePageSearch} />
                </Box>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        keywords: state.purchasesState.keywords,
        searchInFoundKeywords: state.purchasesState.searchInFoundKeywords,
        ignoreKeywords: state.purchasesState.ignoreKeywords,
        shouldSearchInDocuments: state.purchasesState.shouldSearchInDocuments,
        shouldSearchInOkpdNames: state.purchasesState.shouldSearchInOkpdNames,
        havePrepayment: state.purchasesState.havePrepayment,
        haveRecommendation: state.purchasesState.haveRecommendation,
        laws: state.purchasesState.laws,
        datePublicationFrom: state.purchasesState.datePublicationFrom,
        datePublicationTo: state.purchasesState.datePublicationTo,
        dateExpirationFrom: state.purchasesState.dateExpirationFrom,
        dateExpirationTo: state.purchasesState.dateExpirationTo,
        settlements: state.purchasesState.settlements,
        priceFrom: state.purchasesState.priceFrom,
        priceTo: state.purchasesState.priceTo,
        stages: state.purchasesState.stages,
        kladrCodes: state.purchasesState.kladrCodes,
        auctionTypes: state.purchasesState.auctionTypes,
        suggestions: state.purchasesState.suggestions,
        settlementsLoading: state.purchasesState.settlementsLoading,
        okpdCodes: state.purchasesState.okpdCodes,
        industryCodes: state.purchasesState.industryCodes,
        customers: state.purchasesState.customers,
        shouldIncludeEmptyPrice: state.purchasesState.shouldIncludeEmptyPrice,
        currentPage: state.pagesState.currentPage,
        redirectedFromMap: state.purchasesState.redirectedFromMap,
        sortBy: state.purchasesState.sortBy,
        ascending: state.purchasesState.ascending,
        pageSize: state.purchasesState.pageSize
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onResetSearch: (currentPage) => {
            const action = {
                type: 'RESET_SEARCH_PURCHASES',
                currentPage: currentPage
            };
            dispatch(action);
        },
        //isNewSearch - признак того, что проводится новый поиск, а не переход на новую страницу или поиск в поиске
        onSearchPurchases: (state, pageNumber, isNewSearch) => {
            const action = {
                type: 'SEARCH_PURCHASES'
            };
            dispatch(action);

            let okpdCodes = [];
            state.okpdCodes.forEach(async element => {
                if (element.key.length === 1) {
                    const childKeys = element.children.map(opt => opt.key);
                    childKeys.forEach(child => {
                        okpdCodes = okpdCodes.concat(child);
                    });
                } else {
                    okpdCodes = okpdCodes.concat(element.key);
                }
            });

            PurchaseService.search({
                keywords: state.keywords,
                searchInFoundKeywords: state.searchInFoundKeywords,
                ignoreKeywords: state.ignoreKeywords,
                shouldSearchInDocuments: state.shouldSearchInDocuments,
                shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
                havePrepayment: state.havePrepayment,
                haveRecommendation: state.haveRecommendation,
                laws: state.laws,
                pageSize: state.pageSize,
                datePublicationFrom: state.datePublicationFrom,
                datePublicationTo: state.datePublicationTo,
                dateExpirationFrom: state.dateExpirationFrom,
                dateExpirationTo: state.dateExpirationTo,
                priceFrom: parseInt(state.priceFrom),
                priceTo: parseInt(state.priceTo),
                stages: state.stages,
                kladrCodes: state.kladrCodes.map(opt => opt.code),
                auctionTypes: state.auctionTypes,
                shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
                okpdCodes: okpdCodes,
                industryCodes: state.industryCodes.map(opt => {
                    return opt.key;
                }),
                customerIds: state.customers.map(opt => {
                    return opt.id;
                }),
                pageNumber: pageNumber,
                sortBy: state.sortBy,
                asc: state.ascending
            })
                .then(result => {
                    var customerOrgIds = result.collections.map(c => c.customerOrganizationId);

                    CustomerRatingService.getCustomerRatings(customerOrgIds)
                        .then(result => {
                            if (result) {
                                const action = {
                                    type: 'CUSTOMER_RATINGS_FOUND',
                                    customerRatings: result
                                };
                                dispatch(action);
                            }
                        })
                        .catch(() => {
                            const notificationAction = {
                                type: 'SNACKBAR_NOTIFICATION_SENT',
                                notification: {
                                    message: 'Произошла ошибка при получении оценок заказчиков',
                                    variant: 'error'
                                }
                            };
                            dispatch(notificationAction);
                        });

                    var purchaseIds = result.collections.map(c => c.id);
                    PurchaseTagsService.getPurchaseTags(purchaseIds)
                        .then(result => {
                            const action = {
                                type: 'PURCHASE_TAGS_FOUND',
                                purchaseTags: result
                            };
                            dispatch(action);
                        })
                        .catch(() => {
                            const notificationAction = {
                                type: 'SNACKBAR_NOTIFICATION_SENT',
                                notification: {
                                    message: 'Произошла ошибка при получении меток избранного',
                                    variant: 'error'
                                }
                            };
                            dispatch(notificationAction);
                        });

                    const action = {
                        type: 'PURCHASES_FOUND',
                        purchases: result.collections,
                        pageNumber: result.pageNumber,
                        pageSize: result.pageSize,
                        total: result.total,
                        pageCount: result.pageCount,
                        keywords: state.keywords,
                        searchInFoundKeywords: state.searchInFoundKeywords,
                        ignoreKeywords: state.ignoreKeywords,
                        shouldSearchInDocuments: state.shouldSearchInDocuments,
                        shouldSearchInOkpdNames: state.shouldSearchInOkpdNames,
                        havePrepayment: state.havePrepayment,
                        haveRecommendation: state.haveRecommendation,
                        laws: state.laws,
                        datePublicationFrom: state.datePublicationFrom,
                        datePublicationTo: state.datePublicationTo,
                        dateExpirationFrom: state.dateExpirationFrom,
                        dateExpirationTo: state.dateExpirationTo,
                        priceFrom: state.priceFrom,
                        priceTo: state.priceTo,
                        stages: state.stages,
                        kladrCodes: state.kladrCodes,
                        auctionTypes: state.auctionTypes,
                        okpdCodes: state.okpdCodes,
                        industryCodes: state.industryCodes,
                        customers: state.customers,
                        shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice,
                        currentPage: state.currentPage,
                        isNewSearch: isNewSearch
                    };
                    dispatch(action);
                })
                .catch(() => {
                    const action = {
                        type: 'SEARCH_PURCHASES_FAILED'
                    };
                    dispatch(action);
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при поиске',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                })
        },
        onSearchSettlements: (keyword) => {
            const action = {
                type: 'SETTLEMENTS_SEARCH'
            };
            dispatch(action);
            SettlementService.search(keyword)
                .then(settlements => {
                    const action = {
                        type: 'SETTLEMENTS_FOUND',
                        settlements: settlements
                    };
                    dispatch(action);
                })
                .catch(() => {
                    const action = {
                        type: 'SETTLEMENTS_SEARCH_FAILED'
                    };
                    dispatch(action);
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при поиске адреса',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                })
        },
        onClearSettlements: () => {
            const action = {
                type: 'SETTLEMENTS_RESET'
            };
            dispatch(action);
        },
        onKeywordsChanges: (keywords) => {
            const action = {
                type: 'KEYWORDS_CHANGES',
                keywords: keywords
            };
            dispatch(action);
        },
        onSearchInFoundKeywordsChanges: (searchInFoundKeywords) => {
            const action = {
                type: 'SEARCH_IN_FOUND_KEYWORDS_CHANGES',
                searchInFoundKeywords: searchInFoundKeywords
            };
            dispatch(action);
        },
        onIgnoreKeywordsChanges: (ignoreKeywords) => {
            const action = {
                type: 'IGNORE_KEYWORDS_CHANGES',
                ignoreKeywords: ignoreKeywords
            };
            dispatch(action);
        },
        onShouldSearchInDocumentsChanges: (value) => {
            const action = {
                type: 'SHOULD_SEARCH_IN_DOCUMENTS_CHANGES',
                shouldSearchInDocuments: value
            };
            dispatch(action);
        },
        onShouldSearchInOkpdNamesChanges: (value) => {
            const action = {
                type: 'SHOULD_SEARCH_IN_OKPD_NAMES_CHANGES',
                shouldSearchInOkpdNames: value
            };
            dispatch(action);
        },
        onKladrCodesChanges: (kladrCodes) => {
            const action = {
                type: 'KLADR_CODES_CHANGES',
                kladrCodes: kladrCodes
            };
            dispatch(action);
        },
        onAddStage: (stageId) => {
            const action = {
                type: 'ADD_STAGE',
                stageId: stageId
            };
            dispatch(action);
        },
        onRemoveStage: (stageId) => {
            const action = {
                type: 'REMOVE_STAGE',
                stageId: stageId
            };
            dispatch(action);
        },
        onAddAuctionType: (auctionTypeId) => {
            const action = {
                type: 'ADD_AUCTION_TYPE',
                auctionTypeId: auctionTypeId
            };
            dispatch(action);
        },
        onRemoveAuctionType: (auctionTypeId) => {
            const action = {
                type: 'REMOVE_AUCTION_TYPE',
                auctionTypeId: auctionTypeId
            };
            dispatch(action);
        },
        onPriceFromChanges: (value) => {
            const action = {
                type: 'PRICE_FROM_CHANGES',
                priceFrom: value
            };
            dispatch(action);
        },
        onPriceToChanges: (value) => {
            const action = {
                type: 'PRICE_TO_CHANGES',
                priceTo: value
            };
            dispatch(action);
        },
        onExistMinimumPrice: (value) => {
            const action = {
                type: 'SHOULD_INCLUDE_EMPTY_PRICE_CHANGES',
                shouldIncludeEmptyPrice: value
            };
            dispatch(action);
        },
        onHavePrepaymentChanges: (value) => {
            const action = {
                type: 'HAVE_PREPAYMENT_CHANGES',
                havePrepayment: value
            };
            dispatch(action);
        },
        onHaveRecommendationChanges: (value) => {
            const action = {
                type: 'HAVE_RECOMMENDATION_CHANGES',
                haveRecommendation: value
            };
            dispatch(action);
        },
        onDatePublicationFromChanges: (value) => {
            const action = {
                type: 'DATE_PUBLICATION_FROM_CHANGES',
                datePublicationFrom: value
            };
            dispatch(action);
        },
        onDatePublicationToChanges: (value) => {
            const action = {
                type: 'DATE_PUBLICATION_TO_CHANGES',
                datePublicationTo: value
            };
            dispatch(action);
        },
        onDateExpirationFromChanges: (value) => {
            const action = {
                type: 'DATE_EXPIRATION_FROM_CHANGES',
                dateExpirationFrom: value
            };
            dispatch(action);
        },

        onDateExpirationToChanges: (value) => {
            const action = {
                type: 'DATE_EXPIRATION_TO_CHANGES',
                dateExpirationTo: value
            };
            dispatch(action);
        },
        onAddLawChanges: (value) => {
            const action = {
                type: 'ADD_LAW_CHANGES',
                lawId: value
            };
            dispatch(action);
        },
        onRemoveLawChanges: (value) => {
            const action = {
                type: 'REMOVE_LAW_CHANGES',
                lawId: value
            };
            dispatch(action);
        },
        onSearchSuggestions: (keywords) => {
            PurchaseService.searchSuggestions(keywords)
                .then(suggestions => {
                    const action = {
                        type: 'SUGGESTIONS_FOUND',
                        suggestions: suggestions
                    };
                    dispatch(action);
                })
        },
        onOpenOkpdSelection: (checkedOkpdCodes) => {
            const action = {
                type: 'OKPD_SELECTION_OPENED',
                checkedOkpdCodes: checkedOkpdCodes
            };
            dispatch(action);
        },
        onRemoveSingleOkpdSelection: (key) => {
            const action = {
                type: 'SINGLE_SELECTED_OKPD_REMOVED',
                key: key
            };
            dispatch(action);
        },
        onOpenIndustrySelection: (checkedIndustryCodes) => {
            const action = {
                type: 'INDUSTRIES_SELECTION_OPENED',
                checkedIndustryCodes: checkedIndustryCodes
            };
            dispatch(action);
        },
        onRemoveSingleIndustrySelection: (key) => {
            const action = {
                type: 'SINGLE_SELECTED_INDUSTRY_REMOVED',
                key: key
            };
            dispatch(action);
        },
        onOpenSearchOrganizations: (checkedOrganizations) => {
            const action = {
                type: 'CUSTOMER_SELECTION_OPENED',
                checkedOrganizations: checkedOrganizations
            };
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseSearchPanel);
