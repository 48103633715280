import React, { useState } from 'react'
import styles from '../css/PurchaseSearchPanel/PurchaseSearchPanel.module.css'
import {
    Autocomplete,
    Chip, CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    Stack, Switch,
    TextField,
    Tooltip
} from "@mui/material";
import Fade from "@mui/material/Fade";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import BpCheckbox from "./CustomCheckbox";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { StagesList } from "../constants/stages";
import BpCheckboxCircle from "./CustomCheckboxRounded";
import { AuctionTypes } from "../constants/auctionTypes";
import { PurchaseTypes } from "../constants/purchaseTypes";
import { Box } from "@mui/system";
import { CustomDatepickerFromTo } from "./CustomDatepickerFromTo";
import { CustomDatepicker } from "./CustomDatepicker";
import NumberFormat from "react-number-format";
import { isMobile } from "react-device-detect";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator=' '
            isNumericString
            decimalScale={0}
        />
    );
});


const PurchaseSearchForm = (props) => {


    const [settlementsInputTimeout, setSettlementsInputTimeout] = useState(null);

    const handleStageChange = (event) => {
        let isChecked = event.target.checked;
        let value = parseInt(event.target.value);

        if (isChecked) {
            props.onAddStage(value);
        } else {
            props.onRemoveStage(value);
        }
    }

    const handleAuctionTypeChange = (event) => {
        let isChecked = event.target.checked;
        let value = parseInt(event.target.value);

        if (isChecked) {
            props.onAddAuctionType(value);
        } else {
            props.onRemoveAuctionType(value);
        }
    }

    const handlePurchaseTypeChange = (event) => {
        let isChecked = event.target.checked;
        let value = parseInt(event.target.value);

        if (isChecked) {
            props.onAddLawChanges(value);
        } else {
            props.onRemoveLawChanges(value);
        }
    }

    const handleShouldIncludeEmptyPriceChange = (event) => {
        let isChecked = event.target.checked
        props.onExistMinimumPrice(isChecked)
    }

    return (
        <ThemeProvider
            theme={{
                components: {
                    MuiOutlinedInput: {
                        styleOverrides: {
                            root: {
                                padding: "8px 0 8px 12px",
                                borderRadius: "4px",
                                fontSize: "14px",
                                maxWidth: "560px",
                                fontFamily: "SF Pro Text, serif",
                                letterSpacing: "-0.41px",
                                '&:hover fieldset': {
                                    borderColor: "#B2BAC2"
                                },
                                '&:focus-visible': {
                                    borderColor: "#1365E3!important",
                                },
                                '&:focus-visible &:hover fieldset': {
                                    borderColor: "#1365E3!important",
                                },
                                '@media screen and (max-width: 1024px)': {
                                    padding: "9px 0 9px 12px",
                                    fontSize: "16px"
                                }
                            },
                            input: {
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: "3",
                                WebkitBoxOrient: "vertical",
                                padding: "0!important",
                                textOverflow: "ellipsis"
                            }
                        }
                    },
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                backgroundColor: "#FFFFFF",
                                color: "#000000",
                                boxShadow: "-3px 3px 16px 16px #0000000F",
                                fontSize: "13px",
                                padding: "12px",
                                fontFamily: "SF Pro Text, sans-serif",
                                letterSpacing: "-0.41px",
                                fontWeight: "400",
                                maxWidth: "330px",
                                lineHeight: "18px",
                                borderRadius: "8px"
                            },
                            arrow: {
                                color: "#FFFFFF",
                            }
                        },

                    },
                    MuiAutocomplete: {
                        styleOverrides: {
                            popper: {
                                inset: "2px auto auto 0px!important"
                            },
                            paper: {
                                boxShadow: "-3px 3px 16px #0000000F",
                                borderRadius: "10px"
                            },
                            listbox: {
                                padding: "6px 0 0 0",
                                boxShadow: "-3px 3px 16px #0000000F"
                            },
                            input: {
                                padding: "0!important",
                                '@media screen and (max-width: 1024px)': {
                                    padding: "9px 0 9px 12px",
                                    fontSize: "16px"
                                }
                            },
                            inputRoot: {
                                '@media screen and (max-width: 1024px)': {
                                    padding: "9px 12px 9px 12px!important",
                                    fontSize: "16px",
                                    height: "unset!important"
                                }
                            }
                        }
                    },
                    MuiChip: {
                        styleOverrides: {
                            root: {
                                fontFamily: "SF Pro Text, sans-serif",
                                fontSize: "13px",
                                letterSpacing: "-0.41px",
                                borderRadius: "6px"
                            }
                        }
                    },
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                borderRadius: '10px',
                                padding: '10px 18px',
                                fontFamily: 'SF Pro Text, sans-serif',
                                fontSize: "15px",
                                textTransform: "unset!important",
                                letterSpacing: "-0.41px",
                                lineHeight: "unset!important",
                            },
                            input: {
                                overflowWrap: "break-word"
                            },
                            contained: {
                                backgroundColor: '#2B65F0',
                                width: '120px',
                                fontWeight: "500",
                                '&:hover': {
                                    backgroundColor: "#3060DC!important"
                                }
                            },
                            text: {
                                color: "#000000",
                                fontWeight: "400",
                                '&:hover': {
                                    color: "#000000!important"
                                }
                            }
                        }
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            '.Mui-checked+.MuiSwitch-track': {
                                backgroundColor: "#2B65F0"
                            },
                            root: {
                                padding: "6px",
                                marginLeft: "6px",
                                marginRight: "6px"
                            },
                            thumb: {
                                backgroundColor: "#FFFFFF!important",
                                color: "#FFFFFF!important"
                            },
                            track: {
                                borderRadius: "16px",
                                checked: {
                                    opacity: "1"
                                }
                            },
                            switchBase: {
                                '&.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: "#2B65F0",
                                    opacity: "1"
                                },
                            }
                        }
                    }
                }
            }}
        >
            <Grid style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <Grid item xs={12}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Ключевые слова
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                id="keywords"
                                variant="outlined"
                                placeholder="Например: лыжи, лыжные палки, 36.40.11.133"
                                multiline={!isMobile}
                                maxRows={6}
                                minRows={1}
                                fullWidth
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        props.onSearchPurchases(props.searchPanelProps, 0, true);//(props, currentPageNumber, isNewSearch)
                                        props.searchResultsRef.current?.scrollIntoView({ behavior: 'smooth' })
                                    }
                                }}
                                onChange={(event) => {
                                    props.onKeywordsChanges(event.target.value)
                                }}
                                value={props.keywords.join(',')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Tooltip
                                                TransitionComponent={Fade}
                                                arrow
                                                disableHoverListener={isMobile}
                                                leaveTouchDelay={5000}
                                                enterTouchDelay={0}
                                                title={
                                                    <div style={{ whiteSpace: 'pre-line' }}>
                                                        {
                                                            " - если введены несколько слов через пробел \r\n"
                                                            + " ищутся закупки содержащие их все; \r\n"
                                                            + " - альтернативные варианты вводятся через запятую; \r\n"
                                                            + " - для поиска по маске используются знаки * и ?; \r\n"
                                                            + " - * заменяет любое количество любых символов; \r\n"
                                                            + " пример: \r\n"
                                                            + " запрос \"газ*\" вернет \"газообразный\", \"газель\" \r\n"
                                                            + " запрос \"бе*ый\" вернет \"белый\", \"бешеный\" \r\n"
                                                            + " не рекомендуется использовать * в начале слова \r\n"
                                                            + " - ? заменяет один любой символ \r\n"
                                                            + " пример: \r\n"
                                                            + " запрос \"?аска\" вернет \"маска\", \"ласка\" \r\n"
                                                            + " запрос \"ию?ь\" вернет \"июль\", \"июнь\" \r\n"
                                                        }
                                                    </div>
                                                }>
                                                <IconButton size="small">
                                                    <HelpOutlineIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Ключевые слова для поиска в найденном
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                multiline={true}
                                maxRows={isMobile ? 1 : 6}
                                id="searchInFoundKeywords"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    props.onSearchInFoundKeywordsChanges(event.target.value);
                                }}
                                value={props.searchInFoundKeywords.join(',')}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Исключать слова
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                multiline={true}
                                maxRows={isMobile ? 1 : 6}
                                id="ignoreKeywords"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    props.onIgnoreKeywordsChanges(event.target.value);
                                }}
                                value={props.ignoreKeywords.join(',')}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={9}>
                            <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: "24px" }}>
                                <FormControlLabel
                                    className={styles.formControlLabel}
                                    control={isMobile ? <Switch /> : <BpCheckbox />}
                                    label="Искать в приложенных документах"
                                    onChange={(event) => props.onShouldSearchInDocumentsChanges(event.target.checked)}
                                    checked={props.shouldSearchInDocuments}
                                />
                                <FormControlLabel
                                    control={isMobile ? <Switch /> : <BpCheckbox />}
                                    className={styles.formControlLabel}
                                    label="Искать в наименованиях ОКПД2"
                                    onChange={(event) => props.onShouldSearchInOkpdNamesChanges(event.target.checked)}
                                    checked={props.shouldSearchInOkpdNames}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Отрасли
                        </Grid>
                        <Grid item xs={9}>
                            <Stack direction="column" spacing={1}
                                className={styles.autocompleteOptionStack}
                            >
                                {props.industryCodes.map((industry, key) =>
                                    <Chip
                                        className={styles.autocompleteOptionItem}
                                        label={industry.title} key={key}
                                        onDelete={() => props.onRemoveSingleIndustrySelection(industry.key)} />
                                )}
                                <Button sx={{ color: "#2B65F0", marginTop: "0!important", padding: "5px 12px" }}
                                    variant="text"
                                    onClick={() => props.onOpenIndustrySelection(props.industryCodes)}>Добавить</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            ОКПД
                        </Grid>
                        <Grid item xs={9}>
                            <Stack direction="row" spacing={1} className={styles.autocompleteOptionStack}>
                                {props.okpdCodes.map((okpd, key) =>
                                    <Chip
                                        className={styles.autocompleteOptionItem}
                                        label={okpd.key} key={key}
                                        onDelete={() => props.onRemoveSingleOkpdSelection(okpd.key)} />
                                )}
                                <Button
                                    sx={{ color: "#2B65F0", marginTop: "0!important", padding: "5px 12px" }}
                                    variant="text"
                                    onClick={() => props.onOpenOkpdSelection(props.okpdCodes)}>Добавить</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={styles.gridBlock}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Регион поставки
                        </Grid>
                        <Grid item xs={9}>
                            <Autocomplete
                                freeSolo
                                multiple
                                clearOnBlur
                                forcePopupIcon={false}
                                autoHighlight
                                id="checkboxes-tags-demo"
                                options={props.settlements}
                                filterOptions={(options) => options}
                                renderTags={() => (<></>)}
                                getOptionLabel={(option) => option.shortObjectType + ' ' + option.name}
                                loading={props.settlementsLoading}
                                loadingText={
                                    <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                                        <CircularProgress variant='indeterminate' size={20} />
                                        <span style={{
                                            color: "#000000",
                                            fontSize: "14px",
                                            fontFamily: "SF Pro Text, sans-serif",
                                            letterSpacing: "-0.41px"
                                        }}>Загрузка...</span>
                                    </div>
                                }
                                noOptionsText='Ничего не найдено'
                                renderOption={(_props, option) => {
                                    if (!props.kladrCodes.some((kladrCode) => kladrCode.code === option.code)) {
                                        if (option.fullRegionName) {
                                            return (<li {..._props} key={option.code}
                                                className={styles.autocompleteOptionItem}>
                                                {option.shortObjectType + ' ' + option.name + ' '}
                                                <p style={{ color: "#aeb3b7" }}>
                                                    {option.fullRegionName}
                                                </p>
                                            </li>)
                                        } else {
                                            return (<li {..._props} key={option.code}
                                                className={styles.autocompleteOptionItem}>
                                                <p>
                                                    {option.shortObjectType + ' ' + option.name + ' '}
                                                </p>
                                            </li>)
                                        }
                                    }
                                }}
                                renderInput={(params) => (
                                    <ThemeProvider theme={{
                                        components: {
                                            MuiOutlinedInput: {
                                                styleOverrides: {
                                                    root: {
                                                        padding: "8px 12px!important",
                                                        borderRadius: "4px",
                                                        fontSize: "14px",
                                                        maxWidth: "560px",
                                                        height: "36px",
                                                        fontFamily: "SF Pro Text, serif",
                                                        letterSpacing: "-0.41px",
                                                        '&:hover fieldset': {
                                                            borderColor: "#B2BAC2"
                                                        },
                                                        '&:focus-visible': {
                                                            borderColor: "#1365E3!important",
                                                        },
                                                        '&:focus-visible &:hover fieldset': {
                                                            borderColor: "#1365E3!important",
                                                        },
                                                    },
                                                    input: {
                                                        padding: 0
                                                    }
                                                }
                                            }
                                        }
                                    }}>
                                        <TextField
                                            {...params}
                                            placeholder="Область, округ, республика, край, город, деревня, село"
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                if (value) {
                                                    if (settlementsInputTimeout !== null) {
                                                        clearTimeout(settlementsInputTimeout);
                                                    }
                                                    setSettlementsInputTimeout(setTimeout(() => {
                                                        props.onSearchSettlements(value);
                                                    }, 500))

                                                } else {
                                                    clearTimeout(settlementsInputTimeout);
                                                    props.onClearSettlements();
                                                }
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter' && props.settlements.length === 0) {
                                                    event.stopPropagation();
                                                }
                                            }
                                            }
                                        />
                                    </ThemeProvider>

                                )}
                                onChange={(event, options) => {
                                    props.onKladrCodesChanges(options);
                                }}
                                onClose={() => {
                                    props.onClearSettlements();
                                }}
                                value={props.kladrCodes}
                            />

                            <div className={styles.autocompleteValuesContainer}>
                                {
                                    props.kladrCodes.map((value, key) =>
                                        <Tooltip
                                            title={value.fullRegionName}
                                            key={key}
                                        >
                                            <Chip
                                                key={key}
                                                onDelete={() => props.onKladrCodesChanges(props.kladrCodes.filter(entry => entry !== value))}
                                                label={`${value.shortObjectType} ${value.name}`}
                                            />
                                        </Tooltip>
                                    )
                                }
                            </div>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12} className={styles.gridBlock}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                            Этап
                        </Grid>
                        <Grid item xs={9}>
                            <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: "10px" }}>
                                {
                                    StagesList.map((stage) => (
                                        <FormControlLabel
                                            key={stage.id}
                                            className={styles.formControlItem}
                                            control={<BpCheckboxCircle />}
                                            label={stage.name}
                                            onChange={handleStageChange}
                                            value={stage.id}
                                            checked={props.stages.some((val) => val === stage.id)}
                                        />
                                    ))
                                }
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={styles.gridBlock}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                            Метод определения поставщика
                        </Grid>
                        <Grid item xs={9}>
                            <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: "10px" }}>
                                {
                                    AuctionTypes.map((auctionType) => (
                                        <FormControlLabel
                                            key={auctionType.id}
                                            className={styles.formControlItem}
                                            control={<BpCheckboxCircle />}
                                            label={auctionType.name}
                                            onChange={handleAuctionTypeChange}
                                            value={auctionType.id}
                                            checked={props.auctionTypes.some((val) => val === auctionType.id)}
                                        />
                                    ))
                                }
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={styles.gridBlock}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Тип торгов
                        </Grid>
                        <Grid item xs={9}>
                            <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: "10px" }}>
                                {
                                    PurchaseTypes.map((purchaseType) => (
                                        <FormControlLabel
                                            key={purchaseType.id}
                                            className={styles.formControlItem}
                                            control={<BpCheckboxCircle />}
                                            label={purchaseType.name}
                                            onChange={handlePurchaseTypeChange}
                                            value={purchaseType.id}
                                            checked={props.laws.some((val) => val === purchaseType.id)}
                                        />
                                    ))
                                }
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={styles.gridBlock}>
                    <Grid container spacing={3} className={styles.gridRow + " " + styles.gridRowInline}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Наличие аванса
                        </Grid>
                        <Grid item xs={9}>
                            <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={isMobile ? <Switch /> : <BpCheckbox />}
                                    label=""
                                    onChange={(event) => props.onHavePrepaymentChanges(event.target.checked)}
                                    checked={props.havePrepayment}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={styles.gridBlock}>
                    <Grid container spacing={3} className={styles.gridRow + " " + styles.gridRowInline}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Рекомендация "Эксперт Центр"
                        </Grid>
                        <Grid item xs={9}>
                            <FormControlLabel
                                label=''
                                control={isMobile ? <Switch /> : <BpCheckbox />}
                                onChange={(event) => props.onHaveRecommendationChanges(event.target.checked)}
                                checked={props.haveRecommendation}
                            />
                            <Tooltip arrow title="Прогнозируемое снижение менее 5%">
                                <IconButton sx={{ ml: -3 }} size="small">
                                    <HelpOutlineIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={16} className={styles.gridBlock}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Начальная цена
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex' }}>
                            <TextField
                                value={props.priceFrom || ''}
                                onChange={(event) => {
                                    const priceFrom = event.target.value;
                                    props.onPriceFromChanges(priceFrom || null);
                                }}
                                name="numberformat"
                                placeholder="0"
                                id="formatted-numberformat-input"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                variant="outlined"
                            />

                            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', color: "#848489" }}>
                                –
                            </Box>
                            <TextField
                                value={props.priceTo ? props.priceTo : ''}
                                onChange={(event) => {
                                    const priceTo = event.target.value;
                                    props.onPriceToChanges(priceTo || null);
                                }}
                                name="numberformat2"
                                placeholder="0"
                                id="formatted-numberformat-input2"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                variant="outlined"
                            />
                            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', color: "#848489" }}>
                                ₽
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    className={styles.formControlLabel}
                                    control={isMobile ? <Switch /> : <BpCheckbox />}
                                    label="Включая закупки без НМЦ"
                                    onChange={handleShouldIncludeEmptyPriceChange}
                                    // value={1}
                                    checked={props.shouldIncludeEmptyPrice}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={styles.gridBlock}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Опубликовано
                        </Grid>
                        <Grid item xs={9} sx={{ display: 'flex' }}>
                            <CustomDatepickerFromTo
                                dateFrom={props.datePublicationFrom}
                                onDateFromChanges={props.onDatePublicationFromChanges}
                                dateTo={props.datePublicationTo}
                                onDateToChanges={props.onDatePublicationToChanges}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={styles.gridBlock}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Дата окончания подачи заявок не раньше чем
                        </Grid>
                        <Grid item xs={9} sx={{ display: 'flex' }}>
                            <CustomDatepicker
                                date={props.dateExpirationFrom}
                                onDateChanges={props.onDateExpirationFromChanges}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={styles.gridBlock}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Дата окончания подачи заявок не позже чем
                        </Grid>
                        <Grid item xs={9} sx={{ display: 'flex' }}>
                            <CustomDatepicker
                                date={props.dateExpirationTo}
                                onDateChanges={props.onDateExpirationToChanges}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={styles.gridBlockWithoutBorder}>
                    <Grid container spacing={3} className={styles.gridRow}>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            Заказчик
                        </Grid>
                        <Grid item container xs={9} sx={{ display: 'flex' }} className={styles.customersNumberContainer}>
                            {
                                props.customers.length > 0
                                    ?
                                    <Grid item xs={12}>
                                        <Chip className={styles.autocompleteOptionItem}
                                            label={`Выбрано компаний: ${props.customers.length}`} />
                                    </Grid>
                                    :
                                    null
                            }
                            <Grid item xs={12}>
                                <Button
                                    sx={{ color: "#2B65F0" }}
                                    variant="text"
                                    onClick={() => props.onOpenSearchOrganizations(props.customers)}>
                                    {
                                        props.customers.length > 0
                                            ?
                                            'Изменить'
                                            :
                                            'Добавить'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}


export default PurchaseSearchForm
