const initialState = {
  purchases: [],
  pageNumber: 0,
  pageSize: 50,
  total: 0,
  pageCount: 0,
  settlements: [],
  isLoaded: false,
  keywords: [],
  searchInFoundKeywords: [],
  ignoreKeywords: [],
  shouldSearchInDocuments: false,
  shouldSearchInOkpdNames: true,
  havePrepayment: false,
  haveRecommendation: false,
  datePublicationFrom: null,
  datePublicationTo: null,
  dateExpirationFrom: null,
  dateExpirationTo: null,
  priceFrom: null,
  priceTo: null,
  stages: [1],
  laws: [1, 2, 3, 4],
  auctionTypes: [1, 2, 3, 4, 5, 6, 7],
  kladrCodes: [],
  openSelectTagDialog: false,
  selectedPurchase: {},
  suggestions: [],
  tags: [],
  newTagName: '',
  settlementsLoading: false,
  okpdCodes: [],
  industryCodes: [],
  customers: [],
  searchCompleted: false,
  redirectedFromMap: false, //нужно для случаев когда в браузере открыта страница карты и с нее идет переход на поиск
  sortBy: 'purchaseCreateAt',
  ascending: false,
  shouldIncludeEmptyPrice: false
}

const reducer = (state = initialState, action) => {

  if (action.type === 'RESET_SEARCH_PURCHASES') {
    return initialState;
  }

  if (action.type === 'CHANGE_PAGE_SIZE' && action.currentPage === 'PURCHASES') {
    return {
      ...state,
      pageSize: action.pageSize
    }
  }

  if (action.type === 'SEARCH_PURCHASES') {
    return {
      ...state,
      isLoaded: true,
      searchCompleted: false,
      redirectedFromMap: false
    }
  }

  if (action.type === 'SEARCH_PURCHASES_FAILED') {
    return {
      ...state,
      isLoaded: false
    }
  }

  if (action.type === 'PURCHASES_FOUND' && action.currentPage === 'PURCHASES') {
    return {
      ...state,
      purchases: action.purchases,
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      total: action.total,
      pageCount: action.pageCount,
      isLoaded: false,
      keywords: action.keywords,
      searchInFoundKeywords: action.searchInFoundKeywords,
      ignoreKeywords: action.ignoreKeywords,
      shouldSearchInDocuments: action.shouldSearchInDocuments,
      havePrepayment: action.havePrepayment,
      haveRecommendation: action.haveRecommendation,
      laws: action.laws,
      auctionTypes: action.auctionTypes,
      datePublicationFrom: action.datePublicationFrom,
      datePublicationTo: action.datePublicationTo,
      dateExpirationFrom: action.dateExpirationFrom,
      dateExpirationTo: action.dateExpirationTo,
      priceFrom: action.priceFrom,
      priceTo: action.priceTo,
      stages: action.stages,
      kladrCodes: action.kladrCodes,
      okpdCodes: action.okpdCodes,
      industryCodes: action.industryCodes,
      customers: action.customers,
      searchCompleted: true
    }
  }

  if (action.type === 'SETTLEMENTS_SEARCH') {
    return {
      ...state,
      settlementsLoading: true
    }
  }

  if (action.type === 'SETTLEMENTS_SEARCH_FAILED') {
    return {
      ...state,
      settlementsLoading: false
    }
  }

  if (action.type === 'SETTLEMENTS_FOUND') {
    return {
      ...state,
      settlements: action.settlements,
      settlementsLoading: false
    }
  }

  if (action.type === 'SETTLEMENTS_RESET') {
    return {
      ...state,
      settlements: []
    }
  }

  if (action.type === 'KEYWORDS_CHANGES') {
    return {
      ...state,
      keywords: action.keywords.split(',')
    }
  };

  if (action.type === 'SEARCH_IN_FOUND_KEYWORDS_CHANGES') {
    return {
      ...state,
      searchInFoundKeywords: action.searchInFoundKeywords.split(',')
    }
  };

  if (action.type === 'IGNORE_KEYWORDS_CHANGES') {
    return {
      ...state,
      ignoreKeywords: action.ignoreKeywords.split(',')
    }
  };

  if (action.type === 'SHOULD_SEARCH_IN_DOCUMENTS_CHANGES') {
    return {
      ...state,
      shouldSearchInDocuments: action.shouldSearchInDocuments
    }
  };

  if (action.type === 'SHOULD_SEARCH_IN_OKPD_NAMES_CHANGES') {
    return {
      ...state,
      shouldSearchInOkpdNames: action.shouldSearchInOkpdNames
    }
  };

  if (action.type === 'KLADR_CODES_CHANGES') {
    return {
      ...state,
      kladrCodes: action.kladrCodes
    }
  };

  if (action.type === 'ADD_STAGE') {
    var value = action.stageId;

    const stages = [...state.stages, value];

    return {
      ...state,
      stages: stages
    }
  };

  if (action.type === 'REMOVE_STAGE') {
    const value = action.stageId;
    const array = [...state.stages];
    const index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        stages: array
      }
    }
  };

  if (action.type === 'ADD_AUCTION_TYPE') {
    var value = action.auctionTypeId;

    const auctionTypes = [...state.auctionTypes, value];

    return {
      ...state,
      auctionTypes: auctionTypes
    }
  };

  if (action.type === 'REMOVE_AUCTION_TYPE') {
    var value = action.auctionTypeId;
    var array = [...state.auctionTypes];
    var index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);
      return {
        ...state,
        auctionTypes: array
      }
    }
  };

  if (action.type === 'PRICE_FROM_CHANGES') {
    return {
      ...state,
      priceFrom: action.priceFrom
    }
  };

  if (action.type === 'PRICE_TO_CHANGES') {
    return {
      ...state,
      priceTo: action.priceTo
    }
  };

  if (action.type === 'HAVE_PREPAYMENT_CHANGES') {
    return {
      ...state,
      havePrepayment: action.havePrepayment
    }
  };

  if (action.type === 'HAVE_RECOMMENDATION_CHANGES') {
    return {
      ...state,
      haveRecommendation: action.haveRecommendation
    }
  };

  if (action.type === 'DATE_PUBLICATION_FROM_CHANGES') {
    return {
      ...state,
      datePublicationFrom: action.datePublicationFrom
    }
  };

  if (action.type === 'DATE_PUBLICATION_TO_CHANGES') {
    return {
      ...state,
      datePublicationTo: action.datePublicationTo
    }
  };

  if (action.type === 'DATE_EXPIRATION_FROM_CHANGES') {
    return {
      ...state,
      dateExpirationFrom: action.dateExpirationFrom
    }
  }

  if (action.type === 'DATE_EXPIRATION_TO_CHANGES') {
    return {
      ...state,
      dateExpirationTo: action.dateExpirationTo
    }
  }

  if (action.type === 'ADD_LAW_CHANGES') {
    var value = action.lawId;

    const laws = [...state.laws, value];

    return {
      ...state,
      laws: laws
    }
  };

  if (action.type === 'REMOVE_LAW_CHANGES') {
    var value = action.lawId;
    var array = [...state.laws];
    var index = array.indexOf(value)
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        laws: array
      }
    }
  };

  if (action.type === 'SHOULD_INCLUDE_EMPTY_PRICE_CHANGES') {
    return {
      ...state,
      shouldIncludeEmptyPrice: action.shouldIncludeEmptyPrice
    }
  }

  if (action.type === 'SORT_BY_CHANGED' && action.currentPage === 'PURCHASES') {
    const sortByObject = JSON.parse(action.value);
    return {
      ...state,
      sortBy: sortByObject.sortBy,
      ascending: sortByObject.ascending
    }
  }

  if (action.type === 'SUGGESTIONS_FOUND') {
    return {
      ...state,
      suggestions: action.suggestions
    }
  }

  if (action.type === 'CUSTOMER_RATINGS_FOUND') {
    const newPurchases = state.purchases.map((item, index) => {
      let customer = action.customerRatings.find(e => e.customerOrganizationId == item.customerOrganizationId);
      let rating = 0;
      if (customer) {
        rating = customer.rating;
      }
      return {
        ...item,
        customerRating: rating || 0
      }
    })
    return {
      ...state,
      purchases: newPurchases,
    }
  }

  if (action.type === 'PURCHASE_TAGS_FOUND') {
    const newPurchases = state.purchases.map((item, index) => {
      let purchase = action.purchaseTags.find(e => e.id == item.id);
      return {
        ...item,
        tags: purchase.tags
      }
    });
    return {
      ...state,
      purchases: newPurchases
    }
  }

  if (action.type === 'REFRESH_PURCHASE_TAGS') {
    const newPurchases = state.purchases.map((item, index) => {
      if (item.id === action.purchase.id) {
        item.tags = action.purchase.tags
      }
      return {
        ...item
      }
    });
    return {
      ...state,
      purchases: newPurchases
    }
  }

  if (action.type === 'OKPD_SELECTION_COMPLETED' && action.currentPage === 'PURCHASES') {
    const updatedOkpdCodes = [...action.checkedOkpdCodes];

    return {
      ...state,
      okpdCodes: updatedOkpdCodes
    }
  }

  if (action.type === 'INDUSTRIES_SELECTION_COMPLETED' && action.currentPage === 'PURCHASES') {
    state.industryCodes.forEach((item) => {
      action.checkedIndustryCodes.push(item);
    });

    let array = action.checkedIndustryCodes.filter((value, index, self) =>
      index === self.findIndex(t =>
        t.key === value.key
      )
    )

    return {
      ...state,
      industryCodes: array
    }
  }

  if (action.type === 'CUSTOMER_SELECTION_COMPLETED' && action.currentPage === 'PURCHASES') {
    return {
      ...state,
      customers: action.customers
    }
  }

  if (action.type === 'SINGLE_SELECTED_OKPD_REMOVED') {
    const value = action.key;
    const array = state.okpdCodes.slice();
    const deletedOkpd = array.find(okpd => okpd.key === value);
    const index = array.indexOf(deletedOkpd);
    if (index !== -1) {
      array.splice(index, 1);
      return {
        ...state,
        okpdCodes: array
      }
    }
  }

  if (action.type === 'SINGLE_SELECTED_INDUSTRY_REMOVED') {
    const value = action.key;
    const array = state.industryCodes.slice();
    const deletedIndustry = array.find(industry => industry.key === value);
    const index = array.indexOf(deletedIndustry);
    if (index !== -1) {
      array.splice(index, 1);
      return {
        ...state,
        industryCodes: array
      }
    }
  }

  if (action.type === 'CLEAR_UPLOAD_PURCHASES' && action.currentPage === 'PURCHASES') {
    return {
      ...state
    }
  }

  if (action.type === 'MAP_INITIATED') {
    return {
      ...state,
      redirectedFromMap: true
    }
  }

  return state;
}

export default reducer;