import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import PurchaseService from "../../services/PurchaseService";
import {
    Box,
    Button,
    DialogActions,
    DialogTitle,
    Drawer,
    Grid,
    IconButton,
    List,
    SwipeableDrawer,
    Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import "./assets/leaflet.css";

import { Map } from "../Map/map";
import { PurchaseCard } from "./purchase-card";
import MapMarkedPurchasesPanel from "../MapMarkedPurchasesPanel";
import { isMobile } from "react-device-detect";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import DialogContent from "@mui/material/DialogContent";
//import PurchaseCard from "../PurchaseCard";

export const MapSearchPage = () => {

    useEffect(() => {
        const action = {
            type: 'MAP_INITIATED'
        };
        dispatch(action);
        handleSearchGeoClusters();
    }, [])

    const dispatch = useDispatch();
    const reduxState = useSelector((state) => {
        return {
            //TODO: сейчас при смене фильтров на странице поиска надо добавлять дополнительно строки сюда, надо бы подумать как унифицировать
            keywords: state.purchasesState.keywords,
            ignoreKeywords: state.purchasesState.ignoreKeywords,
            shouldSearchInDocuments: state.purchasesState.shouldSearchInDocuments,
            shouldSearchInOkpdNames: state.purchasesState.shouldSearchInOkpdNames,
            havePrepayment: state.purchasesState.havePrepayment,
            haveRecommendation: state.purchasesState.haveRecommendation,
            laws: state.purchasesState.laws,
            datePublicationFrom: state.purchasesState.datePublicationFrom,
            datePublicationTo: state.purchasesState.datePublicationTo,
            dateExpirationFrom: state.purchasesState.dateExpirationFrom,
            dateExpirationTo: state.purchasesState.dateExpirationTo,
            settlements: state.purchasesState.settlements,
            priceFrom: state.purchasesState.priceFrom,
            priceTo: state.purchasesState.priceTo,
            stages: state.purchasesState.stages,
            kladrCodes: state.purchasesState.kladrCodes,
            industryCodes: state.purchasesState.industryCodes,
            auctionTypes: state.purchasesState.auctionTypes,
            suggestions: state.purchasesState.suggestions,
            settlementsLoading: state.purchasesState.settlementsLoading,
            okpdCodes: state.purchasesState.okpdCodes,
            customers: state.purchasesState.customers,
            shouldIncludeEmptyPrice: state.purchasesState.shouldIncludeEmptyPrice,
            southWestLat: state.mapState.southWestLat,
            southWestLng: state.mapState.southWestLng,
            northEastLat: state.mapState.northEastLat,
            northEastLng: state.mapState.northEastLng,
            zoom: state.mapState.zoom,
            purchaseClusters: state.mapState.purchaseClusters,
            isDrawerOpened: state.mapState.isDrawerOpened,
            purchases: state.mapState.purchases,
            uploadPurchaseIds: state.markedPurchasesState.uploadPurchaseIds
        };
    })


    const handleSearchGeoClusters = (northEastLat, northEastLng, southWestLat, southWestLng, zoom) => {
        PurchaseService.getPurchaseClusters({
            keywords: reduxState.keywords,
            ignoreKeywords: reduxState.ignoreKeywords,
            shouldSearchInDocuments: reduxState.shouldSearchInDocuments,
            shouldSearchInOkpdNames: reduxState.shouldSearchInOkpdNames,
            havePrepayment: reduxState.havePrepayment,
            haveRecommendation: reduxState.haveRecommendation,
            laws: reduxState.laws,
            datePublicationFrom: reduxState.datePublicationFrom,
            datePublicationTo: reduxState.datePublicationTo,
            dateExpirationFrom: reduxState.dateExpirationFrom,
            dateExpirationTo: reduxState.dateExpirationTo,
            priceFrom: parseInt(reduxState.priceFrom),
            priceTo: parseInt(reduxState.priceTo),
            stages: reduxState.stages,
            kladrCodes: reduxState.kladrCodes.map(opt => opt.code),
            industryCodes: reduxState.industryCodes.map(opt => opt.key),
            auctionTypes: reduxState.auctionTypes,
            shouldIncludeEmptyPrice: reduxState.shouldIncludeEmptyPrice,
            okpdCodes: reduxState.okpdCodes.flatMap(item => {
                if (item.children.length > 0) {
                    return item.children;
                }
                return item.key;
            }),
            customerIds: reduxState.customers.map(opt => opt.id),
            //в elastic границы northWest и southEast, а в leaflet - northEast и southWest, так что получаем одно из другого
            northWestBound: {
                lat: (northEastLat ? northEastLat : reduxState.northEastLat).toString(),
                lon: (southWestLng ? southWestLng : reduxState.southWestLng).toString()
            },
            southEastBound: {
                lat: (southWestLat ? southWestLat : reduxState.southWestLat).toString(),
                lon: (northEastLng ? northEastLng : reduxState.northEastLng).toString()
            },
            zoom: zoom ? zoom : reduxState.zoom
        })
            .then(result => {
                const action = {
                    type: 'MAP_SET_PURCHASE_CLUSTERS',
                    purchaseClusters: result
                };
                dispatch(action);
            })
    }

    const handleCloseDrawer = () => {
        const action = {
            type: 'MAP_CLOSE_DRAWER'
        };
        dispatch(action);
    }

    const Puller = styled('div')(({ theme }) => ({
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: 'absolute',
        top: 8,
        zIndex: 3,
        left: 'calc(50% - 15px)'
    }));

    return (
        <React.Fragment>
            {
                isMobile ?
                    <SwipeableDrawer
                        anchor="bottom"
                        open={reduxState.isDrawerOpened}
                        onClose={() => handleCloseDrawer()}
                        PaperProps={{
                            sx: {
                                width: "100%",
                                borderRadius: "16px 16px 0 0",
                                maxHeight: "80%",
                            }
                        }}
                        scroll={"paper"}
                    >
                        <Puller />
                        <DialogTitle
                            sx={{
                                m: 0,
                                padding: '32px 16px 12px',
                                fontSize: "20px!important",
                                fontWeight: "600",
                                fontFamily: "SF Pro Text, sans-serif",
                                letterSpacing: "-0.41px"
                            }}
                            id="customized-dialog-title">
                            Закупки
                        </DialogTitle>

                        <DialogContent sx={{ borderBottom: "0.5px solid #E8E8E8", paddingTop: "12px", paddingLeft: "12px", paddingRight: "12px" }}>
                            <List sx={{ pt: 2 }}>

                                {
                                    reduxState.purchases
                                        ?
                                        reduxState.purchases.map((purchase) =>
                                            <PurchaseCard purchase={purchase} key={purchase.id}
                                                isMarked={reduxState.uploadPurchaseIds.some(uploadPurchaseId => uploadPurchaseId === purchase.id)} />
                                        )
                                        :
                                        null
                                }
                                {
                                    reduxState.purchases.length === 50
                                        ?
                                        <Grid container>
                                            <Typography variant="subtitle2">
                                                *отображаются только 50 первых результатов
                                            </Typography>
                                        </Grid>
                                        :
                                        null
                                }
                            </List>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'flex-start', pt: 2, pb: 3, paddingLeft: '16px' }}>
                            <MapMarkedPurchasesPanel />

                            <Button
                                sx={{
                                    borderRadius: '10px',
                                    backgroundColor: '#2B65F0',
                                    padding: '10px 18px',
                                    fontFamily: 'SF Pro Text, serif',
                                    fontSize: "16px",
                                    textTransform: "unset!important",
                                    letterSpacing: "-0.41px",
                                    lineHeight: "unset!important",
                                    fontWeight: "500",
                                    minWidth: "fit-content",
                                    '&:hover': {
                                        backgroundColor: "#3060DC!important"
                                    }
                                }}
                                variant="contained"
                                component={Link} to="/purchases"
                            >Вернуться к фильтру</Button>
                        </DialogActions>
                    </SwipeableDrawer> :
                    <Drawer
                        anchor="left"
                        open={reduxState.isDrawerOpened}
                        variant="persistent"
                        PaperProps={{ sx: { width: 1 / 5, backgroundColor: "#F2F2F7" } }}
                    >
                        <List sx={{ pt: 0 }}>
                            <Grid container style={{ position: "sticky", top: 0, zIndex: 2, marginBottom: 20, width: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        p: 1,
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        width: "100%",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <MapMarkedPurchasesPanel />

                                    <Button
                                        sx={{
                                            borderRadius: '8px',
                                            padding: '4px 12px',
                                            fontFamily: 'SF Pro Text, serif',
                                            fontSize: "14px",
                                            textTransform: "unset!important",
                                            letterSpacing: "-0.41px",
                                            color: "#2B65F0",
                                            fontWeight: "500"
                                        }}
                                        variant="text" component={Link} to="/purchases">
                                        Вернуться к фильтру
                                    </Button>

                                    <IconButton onClick={handleCloseDrawer}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Grid>
                            {
                                reduxState.purchases
                                    ?
                                    reduxState.purchases.map((purchase) =>
                                        <PurchaseCard purchase={purchase} key={purchase.id}
                                            isMarked={reduxState.uploadPurchaseIds.some(uploadPurchaseId => uploadPurchaseId === purchase.id)} />
                                    )
                                    :
                                    null
                            }
                            {
                                reduxState.purchases.length === 50
                                    ?
                                    <Grid container>
                                        <Typography variant="subtitle2">
                                            *отображаются только 50 первых результатов
                                        </Typography>
                                    </Grid>
                                    :
                                    null
                            }
                        </List>
                    </Drawer>
            }
            <Box>
                <Grid container>
                    <Grid item style={{ width: "100vh" }} xs={12}>
                        <Map purchaseClusters={reduxState.purchaseClusters} onMapSearch={handleSearchGeoClusters} />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}
