const initialState = {
  purchases: [],
  pageNumber: 0,
  pageSize: 50,
  total: 0,
  pageCount: 0,
  settlements: [],
  isLoaded: false,

  keywords: [],
  preKeywords: [],

  searchInFoundKeywords: [],

  ignoreKeywords: [],
  preIgnoreKeywords: [],

  shouldSearchInDocuments: false,
  shouldSearchInOkpdNames: true,
  havePrepayment: false,
  haveRecommendation: false,
  laws: [],
  datePublicationFrom: null,
  datePublicationTo: null,
  dateExpirationFrom: null,
  dateExpirationTo: null,
  priceFrom: null,
  priceTo: null,
  stages: [],
  kladrCodes: [],
  auctionTypes: [],
  okpdCodes: [],
  industryCodes: [],
  customers: [],
  shouldIncludeEmptyPrice: false,

  sortBy: 'purchaseCreateAt',
  ascending: false,

  templates: [],
  templateId: '',
  canSaveTemplate: true,
  name: '',
  notifyDays: [],
  notifyIsEnabled: false,

  settlementsLoading: false,

  //надо будет переделывать
  openTagDialog: false,
  selectedPurchase: {},
  uploadFields: [],
  uploadPurchaseIds: [],

  isTemplatesLoading: false,
  isTemplateLoading: false
}

const reducer = (state = initialState, action) => {
  if (action.type === 'TEMPLATES_RESET_SEARCH_PURCHASES') {
    return {
      ...state,
      keywords: [],
      preKeywords: [],
      searchInFoundKeywords: [],
      ignoreKeywords: [],
      preIgnoreKeywords: [],
      shouldSearchInDocuments: false,
      shouldSearchInOkpdNames: true,
      havePrepayment: false,
      haveRecommendation: false,
      laws: [],
      datePublicationFrom: null,
      datePublicationTo: null,
      dateExpirationFrom: null,
      priceFrom: null,
      priceTo: null,
      stages: [],
      kladrCodes: [],
      auctionTypes: [],
      okpdCodes: [],
      industryCodes: [],
      customers: [],
      shouldIncludeEmptyPrice: false,
    };
  }

  if (action.type === 'TEMPLATES_SEARCH_PURCHASES') {
    return {
      ...state,
      isLoaded: true
    }
  }

  if (action.type === 'TEMPLATES_SEARCH_PURCHASES_FAILED') {
    return {
      ...state,
      isLoaded: false
    }
  }

  if (action.type === 'CHANGE_PAGE_SIZE' && action.currentPage === 'TEMPLATES') {
    return {
      ...state,
      pageSize: action.pageSize
    }
  }

  if (action.type === 'PURCHASES_FOUND' && action.currentPage === 'TEMPLATES') {
    return {
      ...state,
      purchases: action.purchases,
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      total: action.total,
      pageCount: action.pageCount,
      isLoaded: false,
      keywords: action.keywords,
      searchInFoundKeywords: action.searchInFoundKeywords,
      ignoreKeywords: action.ignoreKeywords,
      shouldSearchInDocuments: action.shouldSearchInDocuments,
      shouldSearchInOkpdNames: action.shouldSearchInOkpdNames,
      havePrepayment: action.havePrepayment,
      haveRecommendation: action.haveRecommendation,
      laws: action.laws,
      datePublicationFrom: action.datePublicationFrom,
      datePublicationTo: action.datePublicationTo,
      dateExpirationFrom: action.dateExpirationFrom,
      dateExpirationTo: action.dateExpirationTo,
      priceFrom: action.priceFrom,
      priceTo: action.priceTo,
      stages: action.stages,
      kladrCodes: action.kladrCodes,
      auctionTypes: state.auctionTypes,
      okpdCodes: state.okpdCodes,
      industryCodes: state.industryCodes,
      customers: state.customers,
      shouldIncludeEmptyPrice: state.shouldIncludeEmptyPrice
    }
  }

  if (action.type === 'TEMPLATES_SETTLEMENTS_SEARCH') {
    return {
      ...state,
      settlementsLoading: true
    }
  }

  if (action.type === 'TEMPLATES_SETTLEMENTS_SEARCH_FAILED') {
    return {
      ...state,
      settlementsLoading: false
    }
  }

  if (action.type === 'TEMPLATES_SETTLEMENTS_FOUND') {
    return {
      ...state,
      settlements: action.settlements,
      settlementsLoading: false
    }
  }

  if (action.type === 'TEMPLATES_SETTLEMENTS_RESET') {
    return {
      ...state,
      settlements: []
    }
  }

  if (action.type === 'TEMPLATE_LOADING_STARTED') {
    return {
      ...state,
      isTemplateLoading: true
    }
  }

  if (action.type === 'GET_TEMPLATE') {
    return {
      ...state,
      isLoaded: false,
      isTemplateLoading: false,

      keywords: action.keywords,
      preKeywords: action.keywords,

      searchInFoundKeywords: action.searchInFoundKeywords,

      ignoreKeywords: action.ignoreKeywords,
      preIgnoreKeywords: action.preIgnoreKeywords,

      shouldSearchInDocuments: action.shouldSearchInDocuments,
      shouldSearchInOkpdNames: action.shouldSearchInOkpdNames,
      havePrepayment: action.havePrepayment,
      haveRecommendation: action.haveRecommendation,
      laws: action.laws,
      datePublicationFrom: action.datePublicationFrom,
      datePublicationTo: action.datePublicationTo,
      dateExpirationFrom: action.dateExpirationFrom,
      dateExpirationTo: action.dateExpirationTo,
      priceFrom: action.priceFrom,
      priceTo: action.priceTo,
      stages: action.stages,
      kladrCodes: action.kladrCodes,
      templateId: action.templateId,
      auctionTypes: action.auctionTypes,
      okpdCodes: action.okpdCodes,
      industryCodes: action.industryCodes,
      customers: action.customers,
      shouldIncludeEmptyPrice: action.shouldIncludeEmptyPrice,

      name: action.name,
      notifyIsEnabled: action.notifyIsEnabled,
      notifyDays: action.notifyDays
    }
  }

  if (action.type === 'GET_TEMPLATES_STARTED') {
    return {
      ...state,
      isTemplatesLoading: true
    }
  }

  if (action.type === 'GET_TEMPLATES_ERROR') {
    return {
      ...state,
      isTemplatesLoading: false
    }
  }

  if (action.type === 'GET_TEMPLATES_FINISHED') {
    return {
      ...state,
      templates: action.templates,
      isTemplatesLoading: false
    }
  }

  if (action.type === 'REMOVE_TEMPLATE' && action.currentPage === 'TEMPLATES') {
    var array = [...state.templates];
    var index = array.findIndex(x => x.id == action.templateId);
    if (index !== -1) {
      array.splice(index, 1);
      return {
        ...state,
        templates: array,
        templateId: ''
      }
    }
  }

  if (action.type === 'TEMPLATES_KEYWORDS_CHANGES') {
    return {
      ...state,
      keywords: action.keywords.split(',')
    }
  }

  if (action.type === 'TEMPLATES_SEARCH_IN_FOUND_KEYWORDS_CHANGES') {
    return {
      ...state,
      searchInFoundKeywords: action.searchInFoundKeywords.split(',')
    }
  }

  if (action.type === 'TEMPLATES_IGNORE_KEYWORDS_CHANGES') {
    return {
      ...state,
      ignoreKeywords: action.ignoreKeywords.split(',')
    }
  }

  if (action.type === 'TEMPLATES_SHOULD_SEARCH_IN_DOCUMENTS_CHANGES') {
    return {
      ...state,
      shouldSearchInDocuments: action.shouldSearchInDocuments
    }
  }

  if (action.type === 'TEMPLATES_SHOULD_INCLUDE_EMPTY_PRICE_CHANGES') {
    return {
      ...state,
      shouldIncludeEmptyPrice: action.shouldIncludeEmptyPrice
    }
  }

  if (action.type === 'TEMPLATES_SHOULD_SEARCH_IN_OKPD_NAMES_CHANGES') {
    return {
      ...state,
      shouldSearchInOkpdNames: action.shouldSearchInOkpdNames
    }
  };

  if (action.type === 'TEMPLATES_KLADR_CODES_CHANGES') {
    return {
      ...state,
      kladrCodes: action.kladrCodes
    }
  };

  if (action.type === 'TEMPLATES_ADD_STAGE') {
    var value = action.stageId;

    const stages = [...state.stages, value];

    return {
      ...state,
      stages: stages
    }
  };

  if (action.type === 'TEMPLATES_REMOVE_STAGE') {
    var value = action.stageId;
    var array = [...state.stages];
    var index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        stages: array
      }
    }
  };

  if (action.type === 'TEMPLATES_ADD_AUCTION_TYPE') {
    var value = action.auctionTypeId;

    const auctionTypes = [...state.auctionTypes, value];

    return {
      ...state,
      auctionTypes: auctionTypes
    }
  };

  if (action.type === 'TEMPLATES_REMOVE_AUCTION_TYPE') {
    var value = action.auctionTypeId;
    var array = [...state.auctionTypes];
    var index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        auctionTypes: array
      }
    }
  };

  if (action.type === 'TEMPLATES_PRICE_FROM_CHANGES') {
    return {
      ...state,
      priceFrom: action.priceFrom
    }
  };

  if (action.type === 'TEMPLATES_PRICE_TO_CHANGES') {
    return {
      ...state,
      priceTo: action.priceTo
    }
  };

  if (action.type === 'TEMPLATES_HAVE_PREPAYMENT_CHANGES') {
    return {
      ...state,
      havePrepayment: action.havePrepayment
    }
  };

  if (action.type === 'TEMPLATES_HAVE_RECOMMENDATION_CHANGES') {
    return {
      ...state,
      haveRecommendation: action.haveRecommendation
    }
  };

  if (action.type === 'TEMPLATES_DATE_PUBLICATION_FROM_CHANGES') {
    return {
      ...state,
      datePublicationFrom: action.datePublicationFrom
    }
  };

  if (action.type === 'TEMPLATES_DATE_PUBLICATION_TO_CHANGES') {
    return {
      ...state,
      datePublicationTo: action.datePublicationTo
    }
  };

  if (action.type === 'TEMPLATES_DATE_EXPIRATION_FROM_CHANGES') {
    return {
      ...state,
      dateExpirationFrom: action.dateExpirationFrom
    }
  }

  if (action.type === 'TEMPLATES_DATE_EXPIRATION_TO_CHANGES') {
    return {
      ...state,
      dateExpirationTo: action.dateExpirationTo
    }
  }

  if (action.type === 'TEMPLATES_ADD_LAW_CHANGES') {
    var value = action.lawId;

    const laws = [...state.laws, value];

    return {
      ...state,
      laws: laws
    }
  };

  if (action.type === 'TEMPLATES_REMOVE_LAW_CHANGES') {
    var value = action.lawId;
    var array = [...state.laws];
    var index = array.indexOf(value)
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        laws: array
      }
    }
  };

  if (action.type === 'SORT_BY_CHANGED' && action.currentPage === 'TEMPLATES') {
    const sortByObject = JSON.parse(action.value);
    return {
      ...state,
      sortBy: sortByObject.sortBy,
      ascending: sortByObject.ascending
    }
  }

  if (action.type === 'TEMPLATE_NAME_CHANGES') {
    return {
      ...state,
      name: action.templateName
    }
  };

  if (action.type === 'TEMPLATE_SAVED') {
    var templates = [...state.templates];
    const templateId = action.template.id;
    const templateName = action.template.name;

    templates.forEach((template) => {
      if (templateId === template.id) {
        template.name = templateName;
      }
    })

    return {
      ...state,
      templates: templates
    }
  }

  if (action.type === 'ADD_DAY_NOTIFY') {
    var value = action.notifyDay;

    const notifyDays = [...state.notifyDays, value];

    return {
      ...state,
      notifyDays: notifyDays
    }
  }

  if (action.type === 'REMOVE_DAY_NOTIFY') {
    var value = action.notifyDay;
    var array = [...state.notifyDays];
    var index = array.indexOf(value)
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        notifyDays: array
      }
    }
  };

  if (action.type === 'EMAIL_NOTIFY_ENABLE') {
    return {
      ...state,
      notifyIsEnabled: true
    }
  };

  if (action.type === 'EMAIL_NOTIFY_DISABLE') {
    return {
      ...state,
      notifyIsEnabled: false
    }
  };

  //И эти два тоже переделывать
  if (action.type === 'REFRESH_PURCHASE_TAGS') {
    const newPurchases = state.purchases.map((item, index) => {
      if (item.id === action.purchase.id) {
        item.tags = action.purchase.tags
      }
      return {
        ...item
      }
    });
    return {
      ...state,
      purchases: newPurchases,
      openTagDialog: false
    }
  }

  if (action.type === 'OPEN_TAG_DIALOG') {
    return {
      ...state,
      selectedPurchase: action.selectedPurchase,
      openTagDialog: true
    }
  }

  if (action.type === 'OKPD_SELECTION_COMPLETED' && action.currentPage === 'TEMPLATES') {
    const updatedOkpdCodes = [...action.checkedOkpdCodes];

    return {
      ...state,
      okpdCodes: updatedOkpdCodes
    }
  }

  if (action.type === 'INDUSTRIES_SELECTION_COMPLETED' && action.currentPage === 'TEMPLATES') {
    state.industryCodes.forEach((item) => {
      action.checkedIndustryCodes.push(item);
    });

    let array = action.checkedIndustryCodes.filter((value, index, self) =>
      index === self.findIndex(t =>
        t.key === value.key
      )
    )

    return {
      ...state,
      industryCodes: array
    }
  }

  if (action.type === 'CUSTOMER_SELECTION_COMPLETED' && action.currentPage === 'TEMPLATES') {
    return {
      ...state,
      customers: action.customers
    }
  }

  if (action.type === 'TEMPLATES_SINGLE_SELECTED_INDUSTRY_REMOVED') {
    const value = action.key;
    const array = state.industryCodes.slice();
    const deletedIndustry = array.find(industry => industry.key === value);
    const index = array.indexOf(deletedIndustry);
    if (index !== -1) {
      array.splice(index, 1);
      return {
        ...state,
        industryCodes: array
      }
    }
  }

  if (action.type === 'TEMPLATES_SINGLE_SELECTED_OKPD_REMOVED') {
    const value = action.key;
    const array = state.okpdCodes.slice();
    const deletedOkpd = array.find(okpd => okpd.key === value);
    const index = array.indexOf(deletedOkpd);
    if (index !== -1) {
      array.splice(index, 1);
      return {
        ...state,
        okpdCodes: array
      }
    }
  }

  if (action.type === 'ADD_UPLOAD_FIELD' && action.currentPage === 'TEMPLATES') {
    var value = action.uploadField;

    const uploadFields = [...state.uploadFields, value];

    return {
      ...state,
      uploadFields: uploadFields
    }
  }

  if (action.type === 'REMOVE_UPLOAD_FIELD' && action.currentPage === 'TEMPLATES') {
    var value = action.uploadField;
    var array = [...state.uploadFields];
    var index = array.indexOf(value)
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        uploadFields: array
      }
    }
  };

  if (action.type === 'ADD_UPLOAD_PURCHASE' && action.currentPage === 'TEMPLATES') {
    var value = action.uploadPurchaseId;

    const uploadPurchaseIds = [...state.uploadPurchaseIds, value];

    return {
      ...state,
      uploadPurchaseIds: uploadPurchaseIds
    }
  }

  if (action.type === 'REMOVE_UPLOAD_PURCHASE' && action.currentPage === 'TEMPLATES') {
    var value = action.uploadPurchaseId;
    var array = [...state.uploadPurchaseIds];
    var index = array.indexOf(value)
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        uploadPurchaseIds: array
      }
    }
  };

  if (action.type === 'CLEAR_UPLOAD_PURCHASES' && action.currentPage === 'TEMPLATES') {
    return {
      ...state,
      uploadPurchaseIds: []
    }
  }

  if (action.type === 'UPLOAD_SETTINGS' && action.currentPage === 'TEMPLATES') {
    var uploadFields = action.settings;
    return {
      ...state,
      uploadFields: uploadFields
    }
  }

  if (action.type === 'TEMPLATES_CUSTOMER_RATINGS_FOUND') {
    const newPurchases = state.purchases.map((item, index) => {
      let customer = action.customerRatings.find(e => e.customerOrganizationId == item.customerOrganizationId);
      let rating = 0;
      if (customer) {
        rating = customer.rating;
      }
      return {
        ...item,
        customerRating: rating || 0
      }
    })
    return {
      ...state,
      purchases: newPurchases,
    }
  }

  if (action.type === 'TEMPLATES_PURCHASE_TAGS_FOUND') {
    const newPurchases = state.purchases.map((item, index) => {
      let purchase = action.purchaseTags.find(e => e.id == item.id);
      return {
        ...item,
        tags: purchase.tags
      }
    });
    return {
      ...state,
      purchases: newPurchases
    }
  }

  return state;
}

export default reducer;
